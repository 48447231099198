import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/layouts/header';
import Home from './components/layouts/home';
import { useLocation } from 'react-router-dom';
import './App.css';
import OneWayDetails from './components/pages/detailsPage/onewayDetails';
import RoundTripDetails from './components/pages/detailsPage/roundTripDetails';
import EightHoursDetails from './components/pages/detailsPage/hourlyRentDetails/eight_hours_details';
import TenHoursDetails from './components/pages/detailsPage/hourlyRentDetails/ten_Hours_details';
import AirportDetails from './components/pages/detailsPage/airportDetails';
import OneWay_car_details from './components/pages/Select_car_details/oneWay_car_details';
import TwelveHoursDetails from './components/pages/detailsPage/hourlyRentDetails/twelve_hours_details';
import Footer from './components/layouts/footer';
import Blog from './components/layouts/Blog';
import Testimonial from './components/layouts/testimonial_details';
import ContactUs from './components/layouts/contactUs';
import PrivacyPolicy from './components/layouts/privacy_Policy';
import DemoInvoice from './demo_invoice';
import RefundPolicy from './components/layouts/refund_policy';
import ShippingPolicy from './components/layouts/shipping_policy';
import Terms_conditions from './components/layouts/Terms & Conditions';
import AboutUs from './components/layouts/aboutus';
import BlogDetails from './components/layouts/BlogDetails';
import Route_page from './components/layouts/Routes';
import ScrollToTop from './ScrollToTop';
import Success from './components/layouts/success';
import Failed from './components/layouts/failed';
import HomeCarDetails from './components/layouts/home-car-details';


import SuratToAhmedabad from './components/Routes_pages/Surat/SuratToAhmedabad';
import SuratToAnand from './components/Routes_pages/Surat/SuratToAnand';
import SuratToAnkleshwar from './components/Routes_pages/Surat/SuratToAnkleshwar';
import SuratToBharuch from './components/Routes_pages/Surat/SuratToBharuch';
import SuratToDahej from './components/Routes_pages/Surat/SuratToDahej';
import SuratToSaputara from './components/Routes_pages/Surat/SuratToSaputara';
import SuratToAjmer from './components/Routes_pages/Surat/SuratToAjmer';
import SuratToBhiwandi from './components/Routes_pages/Surat/SuratToBhiwandi';
import SuratToHimmatnagar from './components/Routes_pages/Surat/SuratToHimmatnagar';
import SuratToMumbaiAirport from './components/Routes_pages/Surat/SuratToMumbaiAirport';
import SuratToUlhasnagar from './components/Routes_pages/Surat/SuratToUlhasnagar';
import SuratToBadlapur from './components/Routes_pages/Surat/SuratToBadlapur';
import SuratToDadar from './components/Routes_pages/Surat/SuratToDadar';
import SuratToPanvel from './components/Routes_pages/Surat/SuratToPanvel';
import SuratToMumbai from './components/Routes_pages/Surat/SuratToMumbai';
import SuratToKota from './components/Routes_pages/Surat/SuratToKota';
import SuratToAmreli from './components/Routes_pages/Surat/SuratToAmreli';
import SuratToNashik from './components/Routes_pages/Surat/SuratToNashik';
import SuratToNavimumbai from './components/Routes_pages/Surat/SuratToNavimumbai';
import SuratToPune from './components/Routes_pages/Surat/SuratToPune';
import SuratToRajkot from './components/Routes_pages/Surat/SuratToRajkot';
import SuratToLonavala from './components/Routes_pages/Surat/SuratToLonavala';
import SuratToThane from './components/Routes_pages/Surat/SuratToThane';
import SuratToVadodara from './components/Routes_pages/Surat/SuratToVadodara';
import SuratToJodhpur from './components/Routes_pages/Surat/SuratToJodhpur';
import SuratToIndore from './components/Routes_pages/Surat/SuratToIndore';
import SuratToPalitana from './components/Routes_pages/Surat/SuratToPalitana';
import SuratToJaipur from './components/Routes_pages/Surat/SuratToJaipur';
import SuratToJamnagar from './components/Routes_pages/Surat/SuratToJamnagar';
import SuratToBhuj from './components/Routes_pages/Surat/SuratToBhuj';
import SuratToBhavnagar from './components/Routes_pages/Surat/SuratToBhavnagar';
import SuratToDwarka from './components/Routes_pages/Surat/SuratToDwarka';
import SuratToDeolali from './components/Routes_pages/Surat/SuratToDeolali';
import SuratToVeraval from './components/Routes_pages/Surat/SuratToVeraval';
import SuratToVirar from './components/Routes_pages/Surat/SuratToVirar';
import SuratToValsad from './components/Routes_pages/Surat/SuratToValsad';
import SuratToGoa from './components/Routes_pages/Surat/SuratToGoa';
import SuratToUdaipur from './components/Routes_pages/Surat/SuratToUdaipur';
import SuratToDaman from './components/Routes_pages/Surat/SuratToDaman';
import SuratToNavsari from './components/Routes_pages/Surat/SuratToNavsari';
import SuratToVapi from './components/Routes_pages/Surat/SuratToVapi';
import SuratToBorivali from './components/Routes_pages/Surat/SuratToBorivali';
import SuratToKalyan from './components/Routes_pages/Surat/SuratToKalyan';
import SuratToVasai from './components/Routes_pages/Surat/SuratToVasai';
import SuratToSolapur from './components/Routes_pages/Surat/SuratToSolapur';
import SuratToBhopal from './components/Routes_pages/Surat/SuratToBhopal';
import SuratToUjjain from './components/Routes_pages/Surat/SuratToUjjain';
import SuratToBarmer from './components/Routes_pages/Surat/SuratToBarmer';
import SuratToNagpur from './components/Routes_pages/Surat/SuratToNagpur';
import SuratToMorbi from './components/Routes_pages/Surat/SuratToMorbi';
import SuratToDelhi from './components/Routes_pages/Surat/SuratToDelhi';
import SuratToPalanpur from './components/Routes_pages/Surat/SuratToPalanpur';
import SuratToBangalore from './components/Routes_pages/Surat/SuratToBangalore';
import SuratToMahabaleshwar from './components/Routes_pages/Surat/SuratToMahabaleshwar';
import SuratToStatueofunity from './components/Routes_pages/Surat/SuratToStatueofunity';
import SuratToDhari from './components/Routes_pages/Surat/SuratToDhari';
import SuratToShirdi from './components/Routes_pages/Surat/SuratToShirdi';
import SuratToPratapgarh from './components/Routes_pages/Surat/SuratToPratapgarh';
import SuratToPrayagraj from './components/Routes_pages/Surat/SuratToPrayagraj';
import SuratToKanpur from './components/Routes_pages/Surat/SuratToKanpur';
import SuratToJhansi from './components/Routes_pages/Surat/SuratToJhansi';
import SuratToSultanpur from './components/Routes_pages/Surat/SuratToSultanpur';
import SuratToVaranasi from './components/Routes_pages/Surat/SuratToVaranasi';
import SuratToAyodhya from './components/Routes_pages/Surat/SuratToAyodhya';

import SuratToAzamgarh from './components/Routes_pages/Surat/SuratToAzamgarh';
import SuratToRajula from './components/Routes_pages/Surat/SuratToRajula';
import SuratToVansda from './components/Routes_pages/Surat/SuratToVansda';
import SuratToRajpipla from './components/Routes_pages/Surat/SuratToRajpipla';
import SuratToVyara from './components/Routes_pages/Surat/SuratToVyara';
import SuratToKheda from './components/Routes_pages/Surat/SuratToKheda';
import SuratToDhule from './components/Routes_pages/Surat/SuratToDhule';
import SuratToBilimora from './components/Routes_pages/Surat/SuratToBilimora';
import SuratToPadra from './components/Routes_pages/Surat/SuratToPadra';
import SuratToPavagadh from './components/Routes_pages/Surat/SuratToPavagadh';
import SuratToChandod from './components/Routes_pages/Surat/SuratToChandod';
import SuratToMehsana from './components/Routes_pages/Surat/SuratToMehsana';
import SuratToPatan from './components/Routes_pages/Surat/SuratToPatan';
import SuratToVisnagar from './components/Routes_pages/Surat/SuratToVisnagar';
import SuratToKeshod from './components/Routes_pages/Surat/SuratToKeshod';
import SuratToIdar from './components/Routes_pages/Surat/SuratToIdar';
import SuratToAlibag from './components/Routes_pages/Surat/SuratToAlibag';
import SuratToKhopoli from './components/Routes_pages/Surat/SuratToKhopoli';
import SuratToNadiad from './components/Routes_pages/Surat/SuratToNadiad';
import SuratToBoisar from './components/Routes_pages/Surat/SuratToBoisar';
import SuratToBorsad from './components/Routes_pages/Surat/SuratToBorsad';
import SuratToKevadia from './components/Routes_pages/Surat/SuratToKevadia';
import SuratToGandevi from './components/Routes_pages/Surat/SuratToGandevi';
import SuratToSurendranagar from './components/Routes_pages/Surat/SuratToSurendranagar';
import SuratToUnjha from './components/Routes_pages/Surat/SuratToUnjha';
import SuratToUpleta from './components/Routes_pages/Surat/SuratToUpleta';
import SuratToRapar from './components/Routes_pages/Surat/SuratToRapar';
import SuratToKhambhat from './components/Routes_pages/Surat/SuratToKhambhat';
import SuratToJambusar from './components/Routes_pages/Surat/SuratToJambusar';
import SuratToDakor from './components/Routes_pages/Surat/SuratToDakor';
import SuratToTrimbak from './components/Routes_pages/Surat/SuratToTrimbak';
import SuratToNandurbar from './components/Routes_pages/Surat/SuratToNandurbar';
import SuratToSarangpur from './components/Routes_pages/Surat/SuratToSarangpur';
import SuratToPalghar from './components/Routes_pages/Surat/SuratToPalghar';
import SuratToUmargam from './components/Routes_pages/Surat/SuratToUmargam';
import SuratToChikhli from './components/Routes_pages/Surat/SuratToChikhli';
import SuratToAmbernath from './components/Routes_pages/Surat/SuratToAmbernath';
import SuratToUna from './components/Routes_pages/Surat/SuratToUna';
import SuratToSihor from './components/Routes_pages/Surat/SuratToSihor';
import SuratToPalej from './components/Routes_pages/Surat/SuratToPalej';
import SuratToPetlad from './components/Routes_pages/Surat/SuratToPetlad';
import SuratToAkola from './components/Routes_pages/Surat/SuratToAkola';
import SuratToHalvad from './components/Routes_pages/Surat/SuratToHalvad';
import SuratToBardoli from './components/Routes_pages/Surat/SuratToBardoli';
import SuratToHazira from './components/Routes_pages/Surat/SuratToHazira';
import SuratToDharampur from './components/Routes_pages/Surat/SuratToDharampur';
import SuratToAnjar from './components/Routes_pages/Surat/SuratToAnjar';
import SuratToJetpur from './components/Routes_pages/Surat/SuratToJetpur';
import SuratToAmravati from './components/Routes_pages/Surat/SuratToAmravati';
import SuratToHyderabad from './components/Routes_pages/Surat/SuratToHyderabad';
import SuratToWankaner from './components/Routes_pages/Surat/SuratToWankaner';
import SuratToSikar from './components/Routes_pages/Surat/SuratToSikar';



// ahemdabad
import AhmedabadToJodhpur from './components/Routes_pages/Ahmedabad/AhmedabadToJodhpur';
import AhmedabadToAnkleshwar from './components/Routes_pages/Ahmedabad/AhmedabadToAnkleshwar ';
import AhmedabadToBharuch from './components/Routes_pages/Ahmedabad/AhmedabadToBharuch';
import AhmedabadToDhrangadhra from './components/Routes_pages/Ahmedabad/AhmedabadToDhrangadhra';
import AhmedabadToDahej from './components/Routes_pages/Ahmedabad/AhmedabadToDahej';
import AhemdabadToDhari from './components/Routes_pages/Ahmedabad/AhemdabadToDhari';
import AhmedabadToGandhidham from './components/Routes_pages/Ahmedabad/AhmedabadToGandhidham';
import AhmedabadToHimatnagar from './components/Routes_pages/Ahmedabad/AhemdabadToHimatnagar';
import AhemdabadToJamnagar from './components/Routes_pages/Ahmedabad/AhemdabadToJamnagar';
import AhmedabadToBikaner from './components/Routes_pages/Ahmedabad/AhmedabadToBikaner';
import AhemdabadToMumbai from './components/Routes_pages/Ahmedabad/AhmedabadToMumbai';
import AhemdabadToMountAbu from './components/Routes_pages/Ahmedabad/AhemdabadToMountAbu';
import AhemdabadToRajkot from './components/Routes_pages/Ahmedabad/AhemdabadToRajkot';
import AhemdabadToSurat from './components/Routes_pages/Ahmedabad/AhemdabadToSurat';
import AhmedabadToSuratInnovaCab from './components/Routes_pages/Ahmedabad/AhmedabadToSuratInnovaCab';
import AhmedabadToMumbaiInnovaCab from './components/Routes_pages/Ahmedabad/AhmedabadToMumbaiInnovaCab';
import AhemdabadToUdaipur from './components/Routes_pages/Ahmedabad/AhemdabadToUdaipur';
import AhemdabadToVadodara from './components/Routes_pages/Ahmedabad/AhmedabadToVadodara';
import AhemdabadToBhavnagar from './components/Routes_pages/Ahmedabad/AhmedabadToBhavnagar';
import AhemdabadToBhuj from './components/Routes_pages/Ahmedabad/AhemdabadToBhuj';
import AhemdabadToNavsari from './components/Routes_pages/Ahmedabad/AhemdabadToNavasari';
import AhmedabadToPalitana from './components/Routes_pages/Ahmedabad/AhmedabadToPalitana';
import AhmedabadToDakor from './components/Routes_pages/Ahmedabad/AhemdabadToDakor';
import AhemdabadToMorbi from './components/Routes_pages/Ahmedabad/AhemdabadToMorbi';
import AhemdabadToGodhra from './components/Routes_pages/Ahmedabad/AhemdabadToGodhra';
import AhemdabadToUjjain from './components/Routes_pages/Ahmedabad/AhemdabadToUjjain';
import AhmedabadToIndore from './components/Routes_pages/Ahmedabad/AhemdabadToIndore';
import AhemdabadToAmreli from './components/Routes_pages/Ahmedabad/AhemdabadToAmreli';
import AhmedabadToVerval from './components/Routes_pages/Ahmedabad/AhmedabadToVeraval';
import AhemdabadToAbu from './components/Routes_pages/Ahmedabad/AhemdabadToAbuRoad';
import AhmedabadToDelhi from './components/Routes_pages/Ahmedabad/AhemdabadToDelhi';
import AhmedabadToBotad from './components/Routes_pages/Ahmedabad/AhemdabadToBotad';
import AhmedabadToChittorgarh from './components/Routes_pages/Ahmedabad/AhemdabadToChittorgarh';
import AhemdabadToRajula from './components/Routes_pages/Ahmedabad/AhemdabadToRajula';
import AhmedabadToDungarpur from './components/Routes_pages/Ahmedabad/AhemdabadToDungarpur';
import AhmedabadToGondal from './components/Routes_pages/Ahmedabad/AhemdabadToGondal';
import AhmedabadToJaipur from './components/Routes_pages/Ahmedabad/AhemdabadToJaipur';
import AhemdabadToJunagadh from './components/Routes_pages/Ahmedabad/AhemadabadToJunagadh';
import AhemdabadToPalanpur from './components/Routes_pages/Ahmedabad/AhemdabadToPalanpur';
import AhmedabadToModasa from './components/Routes_pages/Ahmedabad/AhemdabadToMoadasa';
import AhemdabadToGoa from './components/Routes_pages/Ahmedabad/AhemdabadToGoa';
import AhemdabadToAbuRoad from './components/Routes_pages/Ahmedabad/AhemdabadToAbuRoad';
import AhemdabadToNathdwara from './components/Routes_pages/Ahmedabad/AhemdabadToNathdwara';
import AhemdabadToPatan from './components/Routes_pages/Ahmedabad/AhemadabadToPatan';
import AhemdabadToPune from './components/Routes_pages/Ahmedabad/AhemdabadToPune';
import AhemdabadToRajpipla from './components/Routes_pages/Ahmedabad/AhemdabadToRajpipla';
import AhemdabadToSomnath from './components/Routes_pages/Ahmedabad/AhemdabadToSomnath';
import AhemdabadToSurendranagar from './components/Routes_pages/Ahmedabad/AhemdabadToSurendranagar';



// mumbai 

import MumbaiToAhemdabad from './components/Routes_pages/Mumbai/MumbaiToAhmedabad';
import MumbaiToAhmednagar from './components/Routes_pages/Mumbai/MumbaiToAhmednagar';
import MumbaiToAurangabad from './components/Routes_pages/Mumbai/MUmbaiToAurangabad';
import MumbaiToRajkot from './components/Routes_pages/Mumbai/MumbaiToRajkot';
import MumbaiToBharuch from './components/Routes_pages/Mumbai/MumbaiToBharuch';
import MumbaiToBikaner from './components/Routes_pages/Mumbai/MumbaiToBikaner';
import MumbaiToDahej from './components/Routes_pages/Mumbai/MumbaiToDahej';
import MumbaiToGoa from './components/Routes_pages/Mumbai/MUmbaiToGoa';
import MumbaiToDeolali from './components/Routes_pages/Mumbai/MumbaiToDeolali';
import MumbaiToPune from './components/Routes_pages/Mumbai/MumbaiToPune';
import MumbaiToSatara from './components/Routes_pages/Mumbai/MumbaiToSatara';
import MumbaiToShirdi from './components/Routes_pages/Mumbai/MumbaiToShiradi';
import MumbaiToNashik from './components/Routes_pages/Mumbai/MumbaiToNashik';
import MumbaiToSurat from './components/Routes_pages/Mumbai/MumbaiToSurat';
import MumbaiToTrimbakeshwar from './components/Routes_pages/Mumbai/MumbaiToTrimbakeshwar';
import MumbaiToVadodara from './components/Routes_pages/Mumbai/MumbaiToVadodara';
import MumbaiToVapi from './components/Routes_pages/Mumbai/MumbaiToVapi';
import MumbaiToNavsari from './components/Routes_pages/Mumbai/MumbaiToNavsari';
import MumbaiToLonavala from './components/Routes_pages/Mumbai/MumbaiToLonavala';
import MumbaiToKhandala from './components/Routes_pages/Mumbai/MumbaiToKhandala';
import MumbaiToBardoli from './components/Routes_pages/Mumbai/MumbaiToBardoli';
import MumbaiToValsad from './components/Routes_pages/Mumbai/MumbaiToValsad';
import MumbaiToKolahpur from './components/Routes_pages/Mumbai/MumbaiToKolahpur';
import MumbaiToPalghar from './components/Routes_pages/Mumbai/MumbaiToPalghar';
import MumbaiToPalanpur from './components/Routes_pages/Mumbai/MumbaiToPalanpur';
import MumbaiToMalegaon from './components/Routes_pages/Mumbai/MumbaiToMalegaon';
import MumbaiToJalgaon from './components/Routes_pages/Mumbai/MumbaiToJalgaon';
import MumbaiToBaramati from './components/Routes_pages/Mumbai/MumbaiToBaramati';
import MumbaiToAkola from './components/Routes_pages/Mumbai/MumbaiToAkola';
import MumbaiToLatur from './components/Routes_pages/Mumbai/MumbaiToLatur';
import MumbaiToDaman from './components/Routes_pages/Mumbai/MubmaiToDaman';
import MumbaiToOsmanabad from './components/Routes_pages/Mumbai/MumbaiToOsmanabad';
import MumbaiToJalna from './components/Routes_pages/Mumbai/MumbaiToJalna';
import MumbaiToNandurbar from './components/Routes_pages/Mumbai/MumbaiToNandurbar';
import MumbaiToDhule from './components/Routes_pages/Mumbai/MumbaiToDhule';
import MumbaiToKhopoli from './components/Routes_pages/Mumbai/MumbaiToKhopoli';
import MumbaiToShirpur from './components/Routes_pages/Mumbai/MumbaiToShirpur';
import MumbaiToAlibag from './components/Routes_pages/Mumbai/MumbaiToAlibag';
import MumbaiToAnkleshwar from './components/Routes_pages/Mumbai/MumbaiToAnkleshwar';
import MumbaiToVeraval from './components/Routes_pages/Mumbai/MumbaiToVeraval';
import MumbaiToVirar from './components/Routes_pages/Mumbai/MumbaiToVirar';
import MumbaiToMatheran from './components/Routes_pages/Mumbai/MumbaiToMatheran';
import MumbaiToIndore from './components/Routes_pages/Mumbai/MumbaiToIndore';
import MumbaiToNagpur from './components/Routes_pages/Mumbai/MumbaiToNagpur';
import MumbaiToSolapur from './components/Routes_pages/Mumbai/MumbaiToSolapur';
import MumbaiToSilvassa from './components/Routes_pages/Mumbai/MumaiToSilvassa';
import MumbaiToBhuj from './components/Routes_pages/Mumbai/MumbaiToBhuj';
import MumbaiToUdaipur from './components/Routes_pages/Mumbai/MumbaiToUdaipur';
import MumbaiToJamnagar from './components/Routes_pages/Mumbai/MUmbaiToJamnagar';
import MumbaiToBhavnagar from './components/Routes_pages/Mumbai/MumbaiToBhavnagar';
import MumbaiCentralToSurat from './components/Routes_pages/Mumbai/MumbaiCentralToSurat';
import MumbaiToSuratSedan from './components/Routes_pages/Mumbai/MumbaiToSuratSeden';
import MumbaiToSuratInnovaCrysta from './components/Routes_pages/Mumbai/MumbaiToSuratInnovaCrysta';
import MumbaiToSuratTempo from './components/Routes_pages/Mumbai/MumbaiToTempo';
import MumbaiToSuratInnova from './components/Routes_pages/Mumbai/MumbaiToSuratInnova';
import MumbaiToBadlapur from './components/Routes_pages/Mumbai/MumbaiToBadlapur';
import MumbaiToBhimashankar from './components/Routes_pages/Mumbai/MumbaiToBhimashankar';
import MumbaiToUmargam from './components/Routes_pages/Mumbai/MumbaiToUmargam';
import MumbaiToParbhani from './components/Routes_pages/Mumbai/MumbaiToParbhani';
import MumbaiToKopargaon from './components/Routes_pages/Mumbai/MumabiToKopargaon';
import MumbaiToMahad from './components/Routes_pages/Mumbai/MumbaiToMahad';
import MumbaiToJabalpur from './components/Routes_pages/Mumbai/MumbaiToJabalpur';
import MumbaiToAmbernath from './components/Routes_pages/Mumbai/MumbaiToAmbernath';
import MumbaiToPadgha from './components/Routes_pages/Mumbai/MumabiToPadgha';
import MumbaiToBhilad from './components/Routes_pages/Mumbai/MumbaiToBhilad';
import MumbaiToVikramgad from './components/Routes_pages/Mumbai/MumbaiToVikramgad';
import MumbaiToVajreshwari from './components/Routes_pages/Mumbai/MumbaiToVajreshwari';
import MumbaiToChandwad from './components/Routes_pages/Mumbai/MumbaiToChandwad';
import MumbaiToGandhinagar from './components/Routes_pages/Mumbai/MumbaiToGandhinagar';
import MumbaiToHazira from './components/Routes_pages/Mumbai/MumbaiToHazira';
import MumbaiToNadiad from './components/Routes_pages/Mumbai/MumbaiToNadiad';
import MumbaiToDharampur from './components/Routes_pages/Mumbai/MumbaiToDharampur';
import MumbaiToKarad from './components/Routes_pages/Mumbai/MumbaiToKarad';
import MumbaiToKarjat from './components/Routes_pages/Mumbai/MumbaiToKarjat';
import MumbaiToUlhasnagar from './components/Routes_pages/Mumbai/MumbaiToUlhasnagar';
import MumbaiToBoisar from './components/Routes_pages/Mumbai/MumbaiToBoisar';
import MumbaiToKheda from './components/Routes_pages/Mumbai/MumbaiToKheda';
import MumbaiToSaputara from './components/Routes_pages/Mumbai/MumbaiToSaputara';
import MumbaiToGandevi from './components/Routes_pages/Mumbai/MumabiToGandevi';
import MumbaiToBhusawal from './components/Routes_pages/Mumbai/MumbaiToBhusawal';
import MumbaiToJawhar from './components/Routes_pages/Mumbai/MumbaiToJawhar';
import MumbaiToTarapur from './components/Routes_pages/Mumbai/MumbaiToTarapur';
import MumbaiToMurbad from './components/Routes_pages/Mumbai/MumbaiToMurbad';
import MumbaiToAnjangaon from './components/Routes_pages/Mumbai/MumbaiTOAnjangaon';
import MumbaiToVyara from './components/Routes_pages/Mumbai/MumbaiToVyara';
import MumbaiToSangamner from './components/Routes_pages/Mumbai/MumbaiToSangamner';
import MumbaiToRatnagiri from './components/Routes_pages/Mumbai/MUmbaiToRatnagiri';
import MumbaiToSangli from './components/Routes_pages/Mumbai/MumbaiTOSangli';
import MumbaiToHyderabad from './components/Routes_pages/Mumbai/MumbaiToHyderabad';
import MumbaiToPardi from './components/Routes_pages/Mumbai/MumbaiToPardi';
import MumbaiToChakan from './components/Routes_pages/Mumbai/MumbaiToChakan';
import MumbaiToRasayani from './components/Routes_pages/Mumbai/MumbaiTORasayani';
import MumbaiToWada from './components/Routes_pages/Mumbai/MUmbaiToWada';
import MumbaiToKasara from './components/Routes_pages/Mumbai/MumbaiToKasara';
import MumbaiToShahapur from './components/Routes_pages/Mumbai/MUmbaiTOShahapur';
import MumbaiToNagothane from './components/Routes_pages/Mumbai/MumbaiToNagothane';
import MumbaiToSinnar from './components/Routes_pages/Mumbai/MumbaiToSinnar';
import MumbaiToBeed from './components/Routes_pages/Mumbai/MUmbaiTOBeed';
import MumbaiToIgatpuri from './components/Routes_pages/Mumbai/MumbaiToIgatpuri';
import MumbaiToBhopal from './components/Routes_pages/Mumbai/MumbaiToBhopal';
import MumbaiToDahanu from './components/Routes_pages/Mumbai/MumbaiToDahanu';
import MumbaiToBilimora from './components/Routes_pages/Mumbai/MumbaiToBilimora';
import MumbaiToManmad from './components/Routes_pages/Mumbai/MumbaiToManmad';
import MumbaiToBengaluru from './components/Routes_pages/Mumbai/MumbaiToBengaluru';
import MumbaiToChikhali from './components/Routes_pages/Mumbai/MumbaiToChikhali';
import MumbaiToManor from './components/Routes_pages/Mumbai/MumbaiToManor';
import MumbaiToLasalgaon from './components/Routes_pages/Mumbai/MubmaiToLasalgaon';
import MumbaiToSakri from './components/Routes_pages/Mumbai/MumbaiToSakri';
import MumbaiToUdvada from './components/Routes_pages/Mumbai/MumbaiToUdvada';


// Daman

import DamanToMumbai from './components/Routes_pages/Daman/DamanToMumbai';
import DamanToMehsana from './components/Routes_pages/Daman/DamanToMehsana';
import DamanToSurat from './components/Routes_pages/Daman/DamanToSurat';
import DamanToAnkleshwar from './components/Routes_pages/Daman/DamanToAnkleshwar';
import DamanToGoa from './components/Routes_pages/Daman/DamanToGoa';
import DamanToBardoli from './components/Routes_pages/Daman/DamanToBardoli';
import DamanToBharuch from './components/Routes_pages/Daman/DamanToBharuch';
import DamanToKalyan from './components/Routes_pages/Daman/DamanToKalyan';
import DamanToLonavala from './components/Routes_pages/Daman/DamanToLonavala';
import DamanToNashik from './components/Routes_pages/Daman/DamanToNashik';
import DamanToNavsari from './components/Routes_pages/Daman/DamanToNavsari';
import DamanToPune from './components/Routes_pages/Daman/DamanToPune';
import DamanToRajkot from './components/Routes_pages/Daman/DamanToRajkot';
import DamanToShirdi from './components/Routes_pages/Daman/DamanToShirdi';
import DamanToMumbaiAirport from './components/Routes_pages/Daman/DamanToMumbaiAirport';
import DamanToHazira from './components/Routes_pages/Daman/DamanToHazira';
import DamanToDahej from './components/Routes_pages/Daman/DamanToDahej';
import DamanToVasaiVirar from './components/Routes_pages/Daman/DamanToVasaiVirar';
import DamanToBhiwandi from './components/Routes_pages/Daman/DamanToBhiwandi';
import DamanToBadlapur from './components/Routes_pages/Daman/DamanToBadlapur';




//vapi

// vapi
import VapiToMumbai from './components/Routes_pages/Vapi/VapiToMumbai';
import VapiToMumbaiAirport from './components/Routes_pages/Vapi/VapiToMumbaiAirport';
import VapiToNaviMumbai from './components/Routes_pages/Vapi/VapiToNaviMumbai';
import VapiToDaman from './components/Routes_pages/Vapi/VapiToDaman';
import VapiToAhmedabad from './components/Routes_pages/Vapi/VapiToAhmedabad';
import VapiToNagpur from './components/Routes_pages/Vapi/VapiToNagpur';
import VapiToSolapur from './components/Routes_pages/Vapi/VapiToSolapur';
import VapiToSurat from './components/Routes_pages/Vapi/VapiToSurat';
import VapiToThane from './components/Routes_pages/Vapi/VapiToThane';
import VapiToNashik from './components/Routes_pages/Vapi/VapiToNashik';
import VapiToPune from './components/Routes_pages/Vapi/VapiToPune';
import VapiToShirdi from './components/Routes_pages/Vapi/VapiToShirdi';
import VapiToBhiwandi from './components/Routes_pages/Vapi/VapiToBhiwandi';
import VapiToBadlapur from './components/Routes_pages/Vapi/VapiToBadlapur';
import VapiToDadar from './components/Routes_pages/Vapi/VapiToDadar';
import VapiToPanvel from './components/Routes_pages/Vapi/VapiToPanvel';
import VapiToMumbaiCentral from './components/Routes_pages/Vapi/VapiToMumbaiCentral';
import VapiToRajkot from './components/Routes_pages/Vapi/VapiToRajkot';
import VapiToBhuj from './components/Routes_pages/Vapi/VapiToBhuj';
import VapiToJamnagar from './components/Routes_pages/Vapi/VapiToJamnagar';
import VapiToUdaipur from './components/Routes_pages/Vapi/VapiToUdaipur';
import VapiToIndore from './components/Routes_pages/Vapi/VapiToIndore';
import VapiToGoa from './components/Routes_pages/Vapi/VapiToGoa';
import VapiToLonavala from './components/Routes_pages/Vapi/VapiToLonavala';
import VapiToAnand from './components/Routes_pages/Vapi/VapiToAnand';
import VapiToBharuch from './components/Routes_pages/Vapi/VapiToBharuch';
import VapiToVadodara from './components/Routes_pages/Vapi/VapiToVadodara';


// valsad
import ValsadToAhemdabad from './components/Routes_pages/Valsad/ValsadToAhmedabad';
import ValsadToBhopal from './components/Routes_pages/Valsad/ValsadToBhopal';
import ValsadToDaman from './components/Routes_pages/Valsad/ValsadToAhmedabad';
import ValsadToGoa from './components/Routes_pages/Valsad/ValsadToGoa';
import ValsadToHazira from './components/Routes_pages/Valsad/ValsadToHazira';
import ValsadToIndore from './components/Routes_pages/Valsad/ValsadToIndore';
import ValsadToLonavala from './components/Routes_pages/Valsad/ValsadToLonavala';
import ValsadToMumbai from './components/Routes_pages/Valsad/ValsadToMumbai';
import ValsadToNashik from './components/Routes_pages/Valsad/ValsadToNashik';
import ValsadToNavsari from './components/Routes_pages/Valsad/ValsadToNavsari';
import ValsadToPune from './components/Routes_pages/Valsad/ValsadToPune';
import ValsadToShirdi from './components/Routes_pages/Valsad/ValsadToShirdi';
import ValsadToSilvassa from './components/Routes_pages/Valsad/ValsadToSilvassa';
import ValsadToSurat from './components/Routes_pages/Valsad/ValsadToSurat';
import ValsadToUjjain from './components/Routes_pages/Valsad/ValsadToUjjain';
import ValsadToVadodara from './components/Routes_pages/Valsad/ValsadToVadodara';
import ValsadToVapi from './components/Routes_pages/Valsad/ValsadToVapi';



// amreli
import AmreliToSurat from './components/Routes_pages/Amreli/AmreliToSurat';
import AmreliToBabra from './components/Routes_pages/Amreli/AmreliToBabra';
import AmreliToRajula from './components/Routes_pages/Amreli/AmreliToRajula';
import AmreliToRajkot from './components/Routes_pages/Amreli/AmreliToRajkot';
import AmreliToPune from './components/Routes_pages/Amreli/AmreliToPune';
import AmreliToAhmedabadAirport from './components/Routes_pages/Amreli/AmreliToAhmedabadAirport';
import AmreliToDhari from './components/Routes_pages/Amreli/AmreliToDhari';
import AmreliToJamnagar from './components/Routes_pages/Amreli/AmreliToJamnagar';
import AmreliToVadodara from './components/Routes_pages/Amreli/AmreliToVadodara';
import AmreliToNavsari from './components/Routes_pages/Amreli/AmreliToNavsari';
import AmreliToDiu from './components/Routes_pages/Amreli/AmreliToDiu';
import AmreliToChotila from './components/Routes_pages/Amreli/AmreliToChotila';
import AmreliToDwarka from './components/Routes_pages/Amreli/AmreliToDwarka';
import AmreliToGoa from './components/Routes_pages/Amreli/AmreliToGoa';
import AmreliToRamdevra from './components/Routes_pages/Amreli/AmreliToRamdevra';
import AmreliToVeraval from './components/Routes_pages/Amreli/AmreliToVeraval';
import AmreliToJunagadh from './components/Routes_pages/Amreli/AmreliToJunagadh';
import AmreliToAhmedabad from './components/Routes_pages/Amreli/AmreliToAhmedabad';
import AmreliToRajkotAirport from './components/Routes_pages/Amreli/AmreliToRajkotAirport';
import AmreliToBhavnagar from './components/Routes_pages/Amreli/AmreliToBhavnagar';
import AmreliToPalitana from './components/Routes_pages/Amreli/AmreliToPalitana';
import AmreliToSavarkundla from './components/Routes_pages/Amreli/AmreliToSavarkundla';
import AmreliToMumbai from './components/Routes_pages/Amreli/AmreliToMumbai';
import AmreliToAmbardiSafariPark from './components/Routes_pages/Amreli/AmreliToAmbardiSafariPark';
import AmreliToGirNationalPark from './components/Routes_pages/Amreli/AmreliToGirNationalPark';
import AmreliToSasanGir from './components/Routes_pages/Amreli/AmreliToSasanGir';


// silvassa

import SilvassaToMumbai from './components/Routes_pages/Silvassa/SilvassaToMumbai';
import SilvassaToGoa from './components/Routes_pages/Silvassa/SilvassaToGoa';
import SilvassaToAhmedabad from './components/Routes_pages/Silvassa/SilvassaToAhmedabad';
import SilvassaToLonavala from './components/Routes_pages/Silvassa/SilvassaToLonavala';
import SilvassaToNashik from './components/Routes_pages/Silvassa/SilvassaToNashik';
import SilvassaToPune from './components/Routes_pages/Silvassa/SilvassaToPune';
import SilvassaToKalyan from './components/Routes_pages/Silvassa/SilvassaToKalyan';
import SilvassaToThane from './components/Routes_pages/Silvassa/SilvassaToThane';
import SilvassaToRajkot from './components/Routes_pages/Silvassa/SilvassaToRajkot';
import SilvassaToUdaipur from './components/Routes_pages/Silvassa/SilvassaToUdaipur';
import SilvassaToBoisar from './components/Routes_pages/Silvassa/SilvassaToBoisar';
import SilvassaToVasaiVirar from './components/Routes_pages/Silvassa/SilvassaToVasaiVirar';
import SilvassaToSurat from './components/Routes_pages/Silvassa/SilvassaToSurat';
import SilvassaToVadodara from './components/Routes_pages/Silvassa/SilvassaToVadodara';






// Bardoli 
import BardoliToMumbai from './components/Routes_pages/Bardoli/BardoliToMumbai';
import BardoliToKalyan from './components/Routes_pages/Bardoli/BardoliToKalyan';
import BardoliToThane from './components/Routes_pages/Bardoli/BardoliToThane';
import BardoliToBhiwandi from './components/Routes_pages/Bardoli/BardoliToBhiwandi';
import BardoliToBadlapur from './components/Routes_pages/Bardoli/BardoliToBadlapur';
import BardoliToDadar from './components/Routes_pages/Bardoli/BardoliToDadar';
import BardoliToPanvel from './components/Routes_pages/Bardoli/BardoliToPanvel';
import BardoliToMumbaiCentral from './components/Routes_pages/Bardoli/BardoliToMumbaiCentral';
import BardoliToVasaiVirar from './components/Routes_pages/Bardoli/BardoliToVasaiVirar';
import BardoliToMumbaiAirport from './components/Routes_pages/Bardoli/BardoliToMumbaiAirport';
import BardoliToNaviMumbai from './components/Routes_pages/Bardoli/BardoliToNaviMumbai';
import BardoliToPune from './components/Routes_pages/Bardoli/BardoliToPune';
import BardoliToAhmedabadAirport from './components/Routes_pages/Bardoli/BardoliToAhmedabadAirport';
import BardoliToSuratAirport from './components/Routes_pages/Bardoli/BardoliToSuratAirport';
import BardoliToNashik from './components/Routes_pages/Bardoli/BardoliToNashik';
import BardoliToShirdi from './components/Routes_pages/Bardoli/BardoliToShirdi';
import BardoliToRajkot from './components/Routes_pages/Bardoli/BardoliToRajkot';
import BardoliToIndore from './components/Routes_pages/Bardoli/BardoliToIndore';
import BardoliToUdaipur from './components/Routes_pages/Bardoli/BardoliToUdaipur';
import BardoliToDaman from './components/Routes_pages/Bardoli/BardoliToDaman';
import BardoliToAnand from './components/Routes_pages/Bardoli/BardoliToAnand';
import BardoliToBharuch from './components/Routes_pages/Bardoli/BardoliToBharuch';
import BardoliToGoa from './components/Routes_pages/Bardoli/BardoliToGoa';
import BardoliToBhuj from './components/Routes_pages/Bardoli/BardoliToBhuj';
import BardoliToJamnagar from './components/Routes_pages/Bardoli/BardoliToJamnagar';
import BardoliToNavsari from './components/Routes_pages/Bardoli/BardoliToNavsari';
import BardoliToSilvassa from './components/Routes_pages/Bardoli/BardoliToSilvassa';
import BardoliToVadodara from './components/Routes_pages/Bardoli/BardoliToVadodara';
import BardoliToVapi from './components/Routes_pages/Bardoli/BardoliToVapi';


// bharuch 
import BharuchToSurat from './components/Routes_pages/Bharuch/BharuchToSurat';
import BharuchToAhmedabadAirport from './components/Routes_pages/Bharuch/BharuchToAhmedabadAirport';
import BharuchToMumbaiAirport from './components/Routes_pages/Bharuch/BharuchToMumbaiAirport';
import BharuchToPune from './components/Routes_pages/Bharuch/BharuchToPune';
import BharuchToDaman from './components/Routes_pages/Bharuch/BharuchToDaman';
import BharuchToVadodara from './components/Routes_pages/Bharuch/BharuchToVadodara';
import BharuchToUdaipur from './components/Routes_pages/Bharuch/BharuchToUdaipur';
import BharuchToVapi from './components/Routes_pages/Bharuch/BharuchToVapi';
import BharuchToSilvassa from './components/Routes_pages/Bharuch/BharuchToSilvassa';
import BharuchToKalyan from './components/Routes_pages/Bharuch/BharuchToKalyan';
import BharuchToBhiwandi from './components/Routes_pages/Bharuch/BharuchToBhiwandi';
import BharuchToNaviMumbai from './components/Routes_pages/Bharuch/BharuchToNaviMumbai';
import BharuchToAjmer from './components/Routes_pages/Bharuch/BharuchToAjmer';
import BharuchToRajkot from './components/Routes_pages/Bharuch/BharuchToRajkot';
import BharuchToIndore from './components/Routes_pages/Bharuch/BharuchToIndore';
import BharuchToGoa from './components/Routes_pages/Bharuch/BharuchToGoa';
import BharuchToBadlapur from './components/Routes_pages/Bharuch/BharuchToBadlapur';
import Login from './components/layouts/Login';

import SEO from './components/SEO';



function App() {

  return (
    <>
      <Router>
        <AppContent />
      </Router>

    </>
  );
}

const AppContent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

    const location = useLocation();

  useEffect(() => {
    // Check if the user has visited before
    const hasVisited = localStorage.getItem('hasVisited');

    if (!hasVisited) {
      setIsModalVisible(true);
      // Set a flag in localStorage to indicate that the user has visited
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  useEffect(() => {
    if (window.location.hostname === "hindustantaxiservice.com") {
      window.location.href = "https://www.hindustantaxiservice.com" + window.location.pathname;
    }
  }, []);
 

  const noFooterPaths = ['/payment-success'];
  const isNoFooter = noFooterPaths.some(path => location.pathname.startsWith(path));
  const noHeaderFooterPaths = ['/payment-success'];

  useEffect(() => {
    // Check if the user has visited before
    const hasVisited = localStorage.getItem('hasVisited');
    const isLoggedIn = localStorage.getItem('isLoggedIn'); // Check login status

    // Show modal if first visit or not logged in
    if (!hasVisited || !isLoggedIn) {
      setIsModalVisible(true);
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
     
      {!noHeaderFooterPaths.includes(location.pathname) && <Header isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />}
 
      <ScrollToTop />
      <div  className="mt-[72px]">
      <Routes>
        {/* surat */}
        <Route path='/surat-to-ahm' element={<> <SEO title="Surat To Ahmedabad - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. We provide one-way, round-trip, and hourly rental services." keywords="taxi service, one-way taxi, round-trip taxi, car rental, travel booking" /> <SuratToAhmedabad /> </>} />

        <Route path='/surat-to-anand' element={<> <SEO title="Surat To Anand - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. We provide one-way, round-trip, and hourly rental services." keywords="taxi service, one-way taxi, round-trip taxi, car rental, travel booking" />  <SuratToAnand /> </>} />


        <Route path='/surat-to-ankleshwar' element={<> <SEO title="Surat To Ankleshwar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Ankleshwar taxi service at affordable prices." /> <SuratToAnkleshwar /> </>} />
        <Route path='/surat-to-bharuch' element={<> <SEO title="Surat To Bharuch - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Bharuch taxi service at affordable prices." /> <SuratToBharuch /> </>} />
        <Route path='/surat-to-dahej' element={<> <SEO title="Surat To Dahej - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Dahej taxi service at affordable prices." /> <SuratToDahej /> </>} />
        <Route path='/surat-to-saputara' element={<> <SEO title="Surat To Saputara - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Saputara taxi service at affordable prices." /> <SuratToSaputara /> </>} />
        <Route path='/surat-to-ajmer' element={<> <SEO title="Surat To Ajmer - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Ajmer taxi service at affordable prices." /> <SuratToAjmer /> </>} />
        <Route path='/surat-to-bhiwandi' element={<> <SEO title="Surat To Bhiwandi - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Bhiwandi taxi service at affordable prices." /> <SuratToBhiwandi /> </>} />
        <Route path='/surat-to-himmatnagar' element={<> <SEO title="Surat To Himmatnagar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Himmatnagar taxi service at affordable prices." /> <SuratToHimmatnagar /> </>} />
        <Route path='/surat-to-mumbai' element={<> <SEO title="Surat To Mumbai - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Mumbai taxi service at affordable prices." /> <SuratToMumbaiAirport /> </>} />
        <Route path='/surat-to-ulhasnagar' element={<> <SEO title="Surat To Ulhasnagar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Ulhasnagar taxi service at affordable prices." /> <SuratToUlhasnagar /> </>} />



        <Route path='/surat-to-badlapur' element={<> <SEO title="Surat To Badlapur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Badlapur taxi service at affordable prices." /> <SuratToBadlapur /> </>} />
        <Route path='/surat-to-dadar' element={<> <SEO title="Surat To Dadar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Dadar taxi service at affordable prices." /> <SuratToDadar /> </>} />
        <Route path='/surat-to-panvel' element={<> <SEO title="Surat To Panvel - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Panvel taxi service at affordable prices." /> <SuratToPanvel /> </>} />
        <Route path='/surat-to-mumbai' element={<> <SEO title="Surat To Mumbai - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Mumbai taxi service at affordable prices." /> <SuratToMumbaiAirport /> </>} />
        <Route path='/surat-to-kota' element={<> <SEO title="Surat To Kota - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Kota taxi service at affordable prices." /> <SuratToKota /> </>} />
        <Route path='/surat-to-amreli' element={<> <SEO title="Surat To Amreli - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Amreli taxi service at affordable prices." /> <SuratToAmreli /> </>} />
        <Route path='/surat-to-nashik' element={<> <SEO title="Surat To Nashik - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Nashik taxi service at affordable prices." /> <SuratToNashik /> </>} />
        <Route path='/surat-to-navimumbai' element={<> <SEO title="Surat To Navi Mumbai - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Navi Mumbai taxi service at affordable prices." /> <SuratToNavimumbai /> </>} />


        <Route path='/surat-to-pune' element={<> <SEO title="Surat To Pune - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Pune taxi service at affordable prices." /> <SuratToPune /> </>} />
        <Route path='/surat-to-rajkot' element={<> <SEO title="Surat To Rajkot - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Rajkot taxi service at affordable prices." /> <SuratToRajkot /> </>} />
        <Route path='/surat-to-lonavala' element={<> <SEO title="Surat To Lonavala - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Lonavala taxi service at affordable prices." /> <SuratToLonavala /> </>} />
        <Route path='/surat-to-thane' element={<> <SEO title="Surat To Thane - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Thane taxi service at affordable prices." /> <SuratToThane /> </>} />
        <Route path='/surat-to-vadodara' element={<> <SEO title="Surat To Vadodara - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Vadodara taxi service at affordable prices." /> <SuratToVadodara /> </>} />
        <Route path='/surat-to-jodhpur' element={<> <SEO title="Surat To Jodhpur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Jodhpur taxi service at affordable prices." /> <SuratToJodhpur /> </>} />
        <Route path='/surat-to-indore' element={<> <SEO title="Surat To Indore - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Indore taxi service at affordable prices." /> <SuratToIndore /> </>} />
        <Route path='/surat-to-palitana' element={<> <SEO title="Surat To Palitana - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Palitana taxi service at affordable prices." /> <SuratToPalitana /> </>} />



        <Route path='/surat-to-jaipur' element={<> <SEO title="Surat To Jaipur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Jaipur taxi service at affordable prices." /> <SuratToJaipur /> </>} />
        <Route path='/surat-to-jamnagar' element={<> <SEO title="Surat To Jamnagar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Jamnagar taxi service at affordable prices." /> <SuratToJamnagar /> </>} />
        <Route path='/surat-to-bhuj' element={<> <SEO title="Surat To Bhuj - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Bhuj taxi service at affordable prices." /> <SuratToBhuj /> </>} />
        <Route path='/surat-to-bhavnagar' element={<> <SEO title="Surat To Bhavnagar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Bhavnagar taxi service at affordable prices." /> <SuratToBhavnagar /> </>} />
        <Route path='/surat-to-dwarka' element={<> <SEO title="Surat To Dwarka - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Dwarka taxi service at affordable prices." /> <SuratToDwarka /> </>} />
        <Route path='/surat-to-deolali' element={<> <SEO title="Surat To Deolali - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Deolali taxi service at affordable prices." /> <SuratToDeolali /> </>} />
        <Route path='/surat-to-veraval' element={<> <SEO title="Surat To Veraval - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Veraval taxi service at affordable prices." /> <SuratToVeraval /> </>} />
        <Route path='/surat-to-virarMaharashtra' element={<> <SEO title="Surat To Virar, Maharashtra - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Virar taxi service at affordable prices." /> <SuratToVirar /> </>} />
        <Route path='/surat-to-valsad' element={<> <SEO title="Surat To Valsad - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Valsad taxi service at affordable prices." /> <SuratToValsad /> </>} />



        <Route path='/surat-to-goa' element={<> <SEO title="Surat To Goa - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Goa taxi service at affordable prices." /> <SuratToGoa /> </>} />
        <Route path='/surat-to-udaipur' element={<> <SEO title="Surat To Udaipur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Udaipur taxi service at affordable prices." /> <SuratToUdaipur /> </>} />
        <Route path='/surat-to-daman' element={<> <SEO title="Surat To Daman - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Daman taxi service at affordable prices." /> <SuratToDaman /> </>} />
        <Route path='/surat-to-navsari' element={<> <SEO title="Surat To Navsari - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Navsari taxi service at affordable prices." /> <SuratToNavsari /> </>} />
        <Route path='/surat-to-vapi' element={<> <SEO title="Surat To Vapi - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Vapi taxi service at affordable prices." /> <SuratToVapi /> </>} />



        <Route path='/surat-to-borivali' element={<> <SEO title="Surat To Borivali - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Borivali taxi service at affordable prices." /> <SuratToBorivali /> </>} />
        <Route path='/surat-to-kalyan' element={<> <SEO title="Surat To Kalyan - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Kalyan taxi service at affordable prices." /> <SuratToKalyan /> </>} />
        <Route path='/surat-to-vasai' element={<> <SEO title="Surat To Vasai - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Vasai taxi service at affordable prices." /> <SuratToVasai /> </>} />
        <Route path='/surat-to-solapur' element={<> <SEO title="Surat To Solapur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Solapur taxi service at affordable prices." /> <SuratToSolapur /> </>} />
        <Route path='/surat-to-bhopal' element={<> <SEO title="Surat To Bhopal - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Bhopal taxi service at affordable prices." /> <SuratToBhopal /> </>} />
        <Route path='/surat-to-ujjain' element={<> <SEO title="Surat To Ujjain - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Ujjain taxi service at affordable prices." /> <SuratToUjjain /> </>} />



        <Route path='/surat-to-ajmer' element={<> <SEO title="Surat To Ajmer - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Ajmer taxi service at affordable prices." /> <SuratToAjmer /> </>} />
        <Route path='/surat-to-barmer' element={<> <SEO title="Surat To Barmer - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Barmer taxi service at affordable prices." /> <SuratToBarmer /> </>} />
        <Route path='/surat-to-nagpur' element={<> <SEO title="Surat To Nagpur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Nagpur taxi service at affordable prices." /> <SuratToNagpur /> </>} />
        <Route path='/surat-to-morbi' element={<> <SEO title="Surat To Morbi - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Morbi taxi service at affordable prices." /> <SuratToMorbi /> </>} />
        <Route path='/surat-to-delhi' element={<> <SEO title="Surat To Delhi - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Delhi taxi service at affordable prices." /> <SuratToDelhi /> </>} />
        <Route path='/surat-to-palanpur' element={<> <SEO title="Surat To Palanpur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Palanpur taxi service at affordable prices." /> <SuratToPalanpur /> </>} />
        <Route path='/surat-to-bangalore' element={<> <SEO title="Surat To Bangalore - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Bangalore taxi service at affordable prices." /> <SuratToBangalore /> </>} />
        <Route path='/surat-to-mahabaleshwar' element={<> <SEO title="Surat To Mahabaleshwar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Mahabaleshwar taxi service at affordable prices." /> <SuratToMahabaleshwar /> </>} />
        <Route path='/surat-to-statueofunity' element={<> <SEO title="Surat To Statue of Unity - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Statue of Unity taxi service at affordable prices." /> <SuratToStatueofunity /> </>} />




        <Route path='/surat-to-dhari' element={<> <SEO title="Surat To Dhari - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Dhari taxi service at affordable prices." /> <SuratToDhari /> </>} />
        <Route path='/surat-to-shirdi' element={<> <SEO title="Surat To Shirdi - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Shirdi taxi service at affordable prices." /> <SuratToShirdi /> </>} />
        <Route path='/surat-to-pratapgarh' element={<> <SEO title="Surat To Pratapgarh - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Pratapgarh taxi service at affordable prices." /> <SuratToPratapgarh /> </>} />
        <Route path='/surat-to-prayagraj' element={<> <SEO title="Surat To Prayagraj - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Prayagraj taxi service at affordable prices." /> <SuratToPrayagraj /> </>} />
        <Route path='/surat-to-kanpur' element={<> <SEO title="Surat To Kanpur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Kanpur taxi service at affordable prices." /> <SuratToKanpur /> </>} />
        <Route path='/surat-to-jhansi' element={<> <SEO title="Surat To Jhansi - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Jhansi taxi service at affordable prices." /> <SuratToJhansi /> </>} />
        <Route path='/surat-to-sultanpur' element={<> <SEO title="Surat To Sultanpur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Sultanpur taxi service at affordable prices." /> <SuratToSultanpur /> </>} />
        <Route path='/surat-to-varanasi' element={<> <SEO title="Surat To Varanasi - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Varanasi taxi service at affordable prices." /> <SuratToVaranasi /> </>} />
        <Route path='/surat-to-ayodhya' element={<> <SEO title="Surat To Ayodhya - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Ayodhya taxi service at affordable prices." /> <SuratToAyodhya /> </>} />
        <Route path='/surat-to-azamgarh' element={<> <SEO title="Surat To Azamgarh - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Azamgarh taxi service at affordable prices." /> <SuratToAzamgarh /> </>} />
        <Route path='/surat-to-rajula' element={<> <SEO title="Surat To Rajula - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Rajula taxi service at affordable prices." /> <SuratToRajula /> </>} />
        <Route path='/surat-to-vasai' element={<> <SEO title="Surat To Vasai - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Vasai taxi service at affordable prices." /> <SuratToVasai /> </>} />




        <Route path='/surat-to-vansda' element={<> <SEO title="Surat To Vansda - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Vansda taxi service at affordable prices." /> <SuratToVansda /> </>} />
        <Route path='/surat-to-rajpipla' element={<> <SEO title="Surat To Rajpipla - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Rajpipla taxi service at affordable prices." /> <SuratToRajpipla /> </>} />
        <Route path='/surat-to-vyara' element={<> <SEO title="Surat To Vyara - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Vyara taxi service at affordable prices." /> <SuratToVyara /> </>} />
        <Route path='/surat-to-kheda' element={<> <SEO title="Surat To Kheda - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Kheda taxi service at affordable prices." /> <SuratToKheda /> </>} />
        <Route path='/surat-to-dhule' element={<> <SEO title="Surat To Dhule - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Dhule taxi service at affordable prices." /> <SuratToDhule /> </>} />
        <Route path='/surat-to-bilimora' element={<> <SEO title="Surat To Bilimora - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Bilimora taxi service at affordable prices." /> <SuratToBilimora /> </>} />
        <Route path='/surat-to-padra' element={<> <SEO title="Surat To Padra - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Padra taxi service at affordable prices." /> <SuratToPadra /> </>} />
        <Route path='/surat-to-pavagadh' element={<> <SEO title="Surat To Pavagadh - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Pavagadh taxi service at affordable prices." /> <SuratToPavagadh /> </>} />
        <Route path='/surat-to-chandod' element={<> <SEO title="Surat To Chandod - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Chandod taxi service at affordable prices." /> <SuratToChandod /> </>} />
        <Route path='/surat-to-mehsana' element={<> <SEO title="Surat To Mehsana - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Mehsana taxi service at affordable prices." /> <SuratToMehsana /> </>} />
        <Route path='/surat-to-patan' element={<> <SEO title="Surat To Patan - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Patan taxi service at affordable prices." /> <SuratToPatan /> </>} />
        <Route path='/surat-to-visnagar' element={<> <SEO title="Surat To Visnagar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Visnagar taxi service at affordable prices." /> <SuratToVisnagar /> </>} />
        <Route path='/surat-to-keshod' element={<> <SEO title="Surat To Keshod - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Keshod taxi service at affordable prices." /> <SuratToKeshod /> </>} />
        <Route path='/surat-to-idar' element={<> <SEO title="Surat To Idar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Idar taxi service at affordable prices." /> <SuratToIdar /> </>} />
        <Route path='/surat-to-anand' element={<> <SEO title="Surat To Anand - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Anand taxi service at affordable prices." /> <SuratToAnand /> </>} />
        <Route path='/surat-to-alibag' element={<> <SEO title="Surat To Alibag - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Alibag taxi service at affordable prices." /> <SuratToAlibag /> </>} />
        <Route path='/surat-to-khopoli' element={<> <SEO title="Surat To Khopoli - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Khopoli taxi service at affordable prices." /> <SuratToKhopoli /> </>} />
        <Route path='/surat-to-nadiad' element={<> <SEO title="Surat To Nadiad - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Nadiad taxi service at affordable prices." /> <SuratToNadiad /> </>} />
        <Route path='/surat-to-boisar' element={<> <SEO title="Surat To Boisar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Boisar taxi service at affordable prices." /> <SuratToBoisar /> </>} />
        <Route path='/surat-to-borsad' element={<> <SEO title="Surat To Borsad - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Borsad taxi service at affordable prices." /> <SuratToBorsad /> </>} />
        <Route path='/surat-to-kevadia' element={<> <SEO title="Surat To Kevadia - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Kevadia taxi service at affordable prices." /> <SuratToKevadia /> </>} />
        <Route path='/surat-to-gandevi' element={<> <SEO title="Surat To Gandevi - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Gandevi taxi service at affordable prices." /> <SuratToGandevi /> </>} />



        <Route path='/surat-to-surendranagar' element={<> <SEO title="Surat To Surendranagar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Surendranagar taxi service at affordable prices." /> <SuratToSurendranagar /> </>} />
        <Route path='/surat-to-unjha' element={<> <SEO title="Surat To Unjha - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Unjha taxi service at affordable prices." /> <SuratToUnjha /> </>} />
        <Route path='/surat-to-upleta' element={<> <SEO title="Surat To Upleta - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Upleta taxi service at affordable prices." /> <SuratToUpleta /> </>} />
        <Route path='/surat-to-rapar' element={<> <SEO title="Surat To Rapar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Rapar taxi service at affordable prices." /> <SuratToRapar /> </>} />
        <Route path='/surat-to-khambhat' element={<> <SEO title="Surat To Khambhat - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Khambhat taxi service at affordable prices." /> <SuratToKhambhat /> </>} />
        <Route path='/surat-to-jambusar' element={<> <SEO title="Surat To Jambusar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Jambusar taxi service at affordable prices." /> <SuratToJambusar /> </>} />
        <Route path='/surat-to-nagpur' element={<> <SEO title="Surat To Nagpur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Nagpur taxi service at affordable prices." /> <SuratToNagpur /> </>} />
        <Route path='/surat-to-solapur' element={<> <SEO title="Surat To Solapur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Solapur taxi service at affordable prices." /> <SuratToSolapur /> </>} />
        <Route path='/surat-to-morbi' element={<> <SEO title="Surat To Morbi - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Morbi taxi service at affordable prices." /> <SuratToMorbi /> </>} />
        <Route path='/surat-to-dakor' element={<> <SEO title="Surat To Dakor - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Dakor taxi service at affordable prices." /> <SuratToDakor /> </>} />
        <Route path='/surat-to-trimbak' element={<> <SEO title="Surat To Trimbak - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Trimbak taxi service at affordable prices." /> <SuratToTrimbak /> </>} />
        <Route path='/surat-to-nandurbar' element={<> <SEO title="Surat To Nandurbar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Nandurbar taxi service at affordable prices." /> <SuratToNandurbar /> </>} />
        <Route path='/surat-to-sarangpur' element={<> <SEO title="Surat To Sarangpur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Sarangpur taxi service at affordable prices." /> <SuratToSarangpur /> </>} />
        <Route path='/surat-to-palghar' element={<> <SEO title="Surat To Palghar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Palghar taxi service at affordable prices." /> <SuratToPalghar /> </>} />
        <Route path='/surat-to-umargam' element={<> <SEO title="Surat To Umargam - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Umargam taxi service at affordable prices." /> <SuratToUmargam /> </>} />
        <Route path='/surat-to-chikhli' element={<> <SEO title="Surat To Chikhli - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Chikhli taxi service at affordable prices." /> <SuratToChikhli /> </>} />
        <Route path='/surat-to-ambernath' element={<> <SEO title="Surat To Ambernath - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Ambernath taxi service at affordable prices." /> <SuratToAmbernath /> </>} />



        <Route path='/surat-to-una' element={<> <SEO title="Surat To Una - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Una taxi service at affordable prices." /> <SuratToUna /> </>} />
        <Route path='/surat-to-sihor' element={<> <SEO title="Surat To Sihor - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Sihor taxi service at affordable prices." /> <SuratToSihor /> </>} />
        <Route path='/surat-to-palej' element={<> <SEO title="Surat To Palej - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Palej taxi service at affordable prices." /> <SuratToPalej /> </>} />
        <Route path='/surat-to-petlad' element={<> <SEO title="Surat To Petlad - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Petlad taxi service at affordable prices." /> <SuratToPetlad /> </>} />
        <Route path='/surat-to-akola' element={<> <SEO title="Surat To Akola - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Akola taxi service at affordable prices." /> <SuratToAkola /> </>} />
        <Route path='/surat-to-deolali' element={<> <SEO title="Surat To Deolali - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Deolali taxi service at affordable prices." /> <SuratToDeolali /> </>} />
        <Route path='/surat-to-valsad' element={<> <SEO title="Surat To Valsad - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Valsad taxi service at affordable prices." /> <SuratToValsad /> </>} />
        <Route path='/surat-to-halvad' element={<> <SEO title="Surat To Halvad - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Halvad taxi service at affordable prices." /> <SuratToHalvad /> </>} />
        <Route path='/surat-to-bardoli' element={<> <SEO title="Surat To Bardoli - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Bardoli taxi service at affordable prices." /> <SuratToBardoli /> </>} />
        <Route path='/surat-to-hazira' element={<> <SEO title="Surat To Hazira - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Hazira taxi service at affordable prices." /> <SuratToHazira /> </>} />
        <Route path='/surat-to-dharampur' element={<> <SEO title="Surat To Dharampur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Dharampur taxi service at affordable prices." /> <SuratToDharampur /> </>} />
        <Route path='/surat-to-anjar' element={<> <SEO title="Surat To Anjar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Anjar taxi service at affordable prices." /> <SuratToAnjar /> </>} />
        <Route path='/surat-to-jetpur' element={<> <SEO title="Surat To Jetpur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Jetpur taxi service at affordable prices." /> <SuratToJetpur /> </>} />
        <Route path='/surat-to-amravati' element={<> <SEO title="Surat To Amravati - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Amravati taxi service at affordable prices." /> <SuratToAmravati /> </>} />
        <Route path='/surat-to-hyderabad' element={<> <SEO title="Surat To Hyderabad - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Hyderabad taxi service at affordable prices." /> <SuratToHyderabad /> </>} />
        <Route path='/surat-to-wankaner' element={<> <SEO title="Surat To Wankaner - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Wankaner taxi service at affordable prices." /> <SuratToWankaner /> </>} />
        <Route path='/surat-to-sikar' element={<> <SEO title="Surat To Sikar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Surat to Sikar taxi service at affordable prices." /> <SuratToSikar /> </>} />








        {/* ahemdabad */}
        <Route path='/ahemdabad-to-jodhpur' element={<> <SEO title="Ahmedabad To Jodhpur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Jodhpur taxi service at affordable prices." /> <AhmedabadToJodhpur /> </>} />
        <Route path='/ahemdabad-to-ankleshwar' element={<> <SEO title="Ahmedabad To Ankleshwar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Ankleshwar taxi service at affordable prices." /> <AhmedabadToAnkleshwar /> </>} />
        <Route path='/ahemdabad-to-bharuch' element={<> <SEO title="Ahmedabad To Bharuch - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Bharuch taxi service at affordable prices." /> <AhmedabadToBharuch /> </>} />
        <Route path='/ahemdabad-to-dhrangadhra' element={<> <SEO title="Ahmedabad To Dhrangadhra - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Dhrangadhra taxi service at affordable prices." /> <AhmedabadToDhrangadhra /> </>} />
        <Route path='/ahemdabad-to-dahej' element={<> <SEO title="Ahmedabad To Dahej - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Dahej taxi service at affordable prices." /> <AhmedabadToDahej /> </>} />
        <Route path='/ahemdabad-to-dhari' element={<> <SEO title="Ahmedabad To Dhari - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Dhari taxi service at affordable prices." /> <AhemdabadToDhari /> </>} />
        <Route path='/ahemdabad-to-gandhidham' element={<> <SEO title="Ahmedabad To Gandhidham - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Gandhidham taxi service at affordable prices." /> <AhmedabadToGandhidham /> </>} />
        <Route path='/ahemdabad-to-himmatnagar' element={<> <SEO title="Ahmedabad To Himatnagar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Himatnagar taxi service at affordable prices." /> <AhmedabadToHimatnagar /> </>} />
        <Route path='/ahemdabad-to-jamnagar' element={<> <SEO title="Ahmedabad To Jamnagar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Jamnagar taxi service at affordable prices." /> <AhemdabadToJamnagar /> </>} />
        <Route path='/ahemdabad-to-bikaner' element={<> <SEO title="Ahmedabad To Bikaner - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Bikaner taxi service at affordable prices." /> <AhmedabadToBikaner /> </>} />
        <Route path='/ahemdabad-to-mumbai' element={<> <SEO title="Ahmedabad To Mumbai - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Mumbai taxi service at affordable prices." /> <AhemdabadToMumbai /> </>} />
        <Route path='/ahemdabad-to-mount-abu' element={<> <SEO title="Ahmedabad To Mount Abu - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Mount Abu taxi service at affordable prices." /> <AhemdabadToMountAbu /> </>} />
        <Route path='/ahemdabad-to-rajkot' element={<> <SEO title="Ahmedabad To Rajkot - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Rajkot taxi service at affordable prices." /> <AhemdabadToRajkot /> </>} />
        <Route path='/ahemdabad-to-surat' element={<> <SEO title="Ahmedabad To Surat - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Surat taxi service at affordable prices." /> <AhemdabadToSurat /> </>} />



        <Route path='/ahemdabad-to-surat-to-innova' element={<> <SEO title="Ahmedabad To Surat - Innova Taxi Service" description="Book an Innova taxi from Ahmedabad to Surat with Hindustan Taxi Service at affordable prices." /> <AhmedabadToSuratInnovaCab /> </>} />
        <Route path='/ahemdabad-to-mumbai-to-innova' element={<> <SEO title="Ahmedabad To Mumbai - Innova Taxi Service" description="Book an Innova taxi from Ahmedabad to Mumbai with Hindustan Taxi Service at affordable prices." /> <AhmedabadToMumbaiInnovaCab /> </>} />
        <Route path='/ahemdabad-to-udaipur' element={<> <SEO title="Ahmedabad To Udaipur - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Udaipur taxi service at affordable prices." /> <AhemdabadToUdaipur /> </>} />
        <Route path='/ahemdabad-to-vadodara' element={<> <SEO title="Ahmedabad To Vadodara - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Vadodara taxi service at affordable prices." /> <AhemdabadToVadodara /> </>} />
        <Route path='/ahemdabad-to-bhavnagar' element={<> <SEO title="Ahmedabad To Bhavnagar - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Bhavnagar taxi service at affordable prices." /> <AhemdabadToBhavnagar /> </>} />
        <Route path='/ahemdabad-to-bhuj' element={<> <SEO title="Ahmedabad To Bhuj - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Bhuj taxi service at affordable prices." /> <AhemdabadToBhuj /> </>} />
        <Route path='/ahemdabad-to-navsari' element={<> <SEO title="Ahmedabad To Navsari - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Navsari taxi service at affordable prices." /> <AhemdabadToNavsari /> </>} />
        <Route path='/ahemdabad-to-palitana' element={<> <SEO title="Ahmedabad To Palitana - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Palitana taxi service at affordable prices." /> <AhmedabadToPalitana /> </>} />
        <Route path='/ahemdabad-to-dakor' element={<> <SEO title="Ahmedabad To Dakor - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Dakor taxi service at affordable prices." /> <AhmedabadToDakor /> </>} />
        <Route path='/ahemdabad-to-morbi' element={<> <SEO title="Ahmedabad To Morbi - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Morbi taxi service at affordable prices." /> <AhemdabadToMorbi /> </>} />
        <Route path='/ahemdabad-to-godhara' element={<> <SEO title="Ahmedabad To Godhra - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Godhra taxi service at affordable prices." /> <AhemdabadToGodhra /> </>} />
        <Route path='/ahemdabad-to-ujjain' element={<> <SEO title="Ahmedabad To Ujjain - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Ujjain taxi service at affordable prices." /> <AhemdabadToUjjain /> </>} />
        <Route path='/ahemdabad-to-indore' element={<> <SEO title="Ahmedabad To Indore - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Indore taxi service at affordable prices." /> <AhmedabadToIndore /> </>} />
        <Route path='/ahemdabad-to-amreli' element={<> <SEO title="Ahmedabad To Amreli - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Amreli taxi service at affordable prices." /> <AhemdabadToAmreli /> </>} />
        <Route path='/ahemdabad-to-veraval' element={<> <SEO title="Ahmedabad To Veraval - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Veraval taxi service at affordable prices." /> <AhmedabadToVerval /> </>} />
        <Route path='/ahemdabad-to-mountabu' element={<> <SEO title="Ahmedabad To Mount Abu - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Mount Abu taxi service at affordable prices." /> <AhemdabadToAbu /> </>} />
        <Route path='/ahemdabad-to-delhi' element={<> <SEO title="Ahmedabad To Delhi - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Delhi taxi service at affordable prices." /> <AhmedabadToDelhi /> </>} />
        <Route path='/ahemdabad-to-botad' element={<> <SEO title="Ahmedabad To Botad - Hindustan Taxi Service" description="Book a taxi with Hindustan Taxi Service. Ahmedabad to Botad taxi service at affordable prices." /> <AhmedabadToBotad /> </>} />



        <Route path='/ahemdabad-to-chittorgarh' element={<> <SEO title="Ahmedabad To Chittorgarh - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Chittorgarh with Hindustan Taxi Service at affordable prices." /> <AhmedabadToChittorgarh /> </>} />
        <Route path='/ahemdabad-to-rajula' element={<> <SEO title="Ahmedabad To Rajula - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Rajula with Hindustan Taxi Service at affordable prices." /> <AhemdabadToRajula /> </>} />
        <Route path='/ahemdabad-to-dungarpur' element={<> <SEO title="Ahmedabad To Dungarpur - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Dungarpur with Hindustan Taxi Service at affordable prices." /> <AhmedabadToDungarpur /> </>} />
        <Route path='/ahemdabad-to-gondal' element={<> <SEO title="Ahmedabad To Gondal - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Gondal with Hindustan Taxi Service at affordable prices." /> <AhmedabadToGondal /> </>} />
        <Route path='/ahemdabad-to-jaipur' element={<> <SEO title="Ahmedabad To Jaipur - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Jaipur with Hindustan Taxi Service at affordable prices." /> <AhmedabadToJaipur /> </>} />
        <Route path='/ahemdabad-to-junagadh' element={<> <SEO title="Ahmedabad To Junagadh - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Junagadh with Hindustan Taxi Service at affordable prices." /> <AhemdabadToJunagadh /> </>} />
        <Route path='/ahemdabad-to-palanpur' element={<> <SEO title="Ahmedabad To Palanpur - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Palanpur with Hindustan Taxi Service at affordable prices." /> <AhemdabadToPalanpur /> </>} />
        <Route path='/ahemdabad-to-modasa' element={<> <SEO title="Ahmedabad To Modasa - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Modasa with Hindustan Taxi Service at affordable prices." /> <AhmedabadToModasa /> </>} />
        <Route path='/ahemdabad-to-goa' element={<> <SEO title="Ahmedabad To Goa - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Goa with Hindustan Taxi Service at affordable prices." /> <AhemdabadToGoa /> </>} />
        <Route path='/ahemdabad-to-aburoad' element={<> <SEO title="Ahmedabad To Abu Road - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Abu Road with Hindustan Taxi Service at affordable prices." /> <AhemdabadToAbuRoad /> </>} />
        <Route path='/ahemdabad-to-nathdwara' element={<> <SEO title="Ahmedabad To Nathdwara - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Nathdwara with Hindustan Taxi Service at affordable prices." /> <AhemdabadToNathdwara /> </>} />
        <Route path='/ahemdabad-to-patan' element={<> <SEO title="Ahmedabad To Patan - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Patan with Hindustan Taxi Service at affordable prices." /> <AhemdabadToPatan /> </>} />
        <Route path='/ahemdabad-to-pune' element={<> <SEO title="Ahmedabad To Pune - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Pune with Hindustan Taxi Service at affordable prices." /> <AhemdabadToPune /> </>} />
        <Route path='/ahemdabad-to-rajpipla' element={<> <SEO title="Ahmedabad To Rajpipla - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Rajpipla with Hindustan Taxi Service at affordable prices." /> <AhemdabadToRajpipla /> </>} />
        <Route path='/ahemdabad-to-somnath' element={<> <SEO title="Ahmedabad To Somnath - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Somnath with Hindustan Taxi Service at affordable prices." /> <AhemdabadToSomnath /> </>} />
        <Route path='/ahemdabad-to-surendranagar' element={<> <SEO title="Ahmedabad To Surendranagar - Hindustan Taxi Service" description="Book a taxi from Ahmedabad to Surendranagar with Hindustan Taxi Service at affordable prices." /> <AhemdabadToSurendranagar /> </>} />





        {/* Daman */}

        <Route path='/daman-to-mumbai' element={<> <SEO title="Daman To Mumbai - Hindustan Taxi Service" description="Book a taxi from Daman to Mumbai with Hindustan Taxi Service at affordable prices." /> <DamanToMumbai /> </>} />
        <Route path='/daman-to-mehsana' element={<> <SEO title="Daman To Mehsana - Hindustan Taxi Service" description="Book a taxi from Daman to Mehsana with Hindustan Taxi Service at affordable prices." /> <DamanToMehsana /> </>} />
        <Route path='/daman-to-surat' element={<> <SEO title="Daman To Surat - Hindustan Taxi Service" description="Book a taxi from Daman to Surat with Hindustan Taxi Service at affordable prices." /> <DamanToSurat /> </>} />
        <Route path='/daman-to-ankleshwar' element={<> <SEO title="Daman To Ankleshwar - Hindustan Taxi Service" description="Book a taxi from Daman to Ankleshwar with Hindustan Taxi Service at affordable prices." /> <DamanToAnkleshwar /> </>} />
        <Route path='/daman-to-goa' element={<> <SEO title="Daman To Goa - Hindustan Taxi Service" description="Book a taxi from Daman to Goa with Hindustan Taxi Service at affordable prices." /> <DamanToGoa /> </>} />
        <Route path='/daman-to-bardoli' element={<> <SEO title="Daman To Bardoli - Hindustan Taxi Service" description="Book a taxi from Daman to Bardoli with Hindustan Taxi Service at affordable prices." /> <DamanToBardoli /> </>} />
        <Route path='/daman-to-bharuch' element={<> <SEO title="Daman To Bharuch - Hindustan Taxi Service" description="Book a taxi from Daman to Bharuch with Hindustan Taxi Service at affordable prices." /> <DamanToBharuch /> </>} />
        <Route path='/daman-to-kalyan' element={<> <SEO title="Daman To Kalyan - Hindustan Taxi Service" description="Book a taxi from Daman to Kalyan with Hindustan Taxi Service at affordable prices." /> <DamanToKalyan /> </>} />
        <Route path='/daman-to-lonavala' element={<> <SEO title="Daman To Lonavala - Hindustan Taxi Service" description="Book a taxi from Daman to Lonavala with Hindustan Taxi Service at affordable prices." /> <DamanToLonavala /> </>} />
        <Route path='/daman-to-nashik' element={<> <SEO title="Daman To Nashik - Hindustan Taxi Service" description="Book a taxi from Daman to Nashik with Hindustan Taxi Service at affordable prices." /> <DamanToNashik /> </>} />
        <Route path='/daman-to-navsari' element={<> <SEO title="Daman To Navsari - Hindustan Taxi Service" description="Book a taxi from Daman to Navsari with Hindustan Taxi Service at affordable prices." /> <DamanToNavsari /> </>} />
        <Route path='/daman-to-pune' element={<> <SEO title="Daman To Pune - Hindustan Taxi Service" description="Book a taxi from Daman to Pune with Hindustan Taxi Service at affordable prices." /> <DamanToPune /> </>} />
        <Route path='/daman-to-rajkot' element={<> <SEO title="Daman To Rajkot - Hindustan Taxi Service" description="Book a taxi from Daman to Rajkot with Hindustan Taxi Service at affordable prices." /> <DamanToRajkot /> </>} />
        <Route path='/daman-to-shirdi' element={<> <SEO title="Daman To Shirdi - Hindustan Taxi Service" description="Book a taxi from Daman to Shirdi with Hindustan Taxi Service at affordable prices." /> <DamanToShirdi /> </>} />
        <Route path='/daman-to-mumbaiairport' element={<> <SEO title="Daman To Mumbai Airport - Hindustan Taxi Service" description="Book a taxi from Daman to Mumbai Airport with Hindustan Taxi Service at affordable prices." /> <DamanToMumbaiAirport /> </>} />
        <Route path='/daman-to-hazira' element={<> <SEO title="Daman To Hazira - Hindustan Taxi Service" description="Book a taxi from Daman to Hazira with Hindustan Taxi Service at affordable prices." /> <DamanToHazira /> </>} />
        <Route path='/daman-to-dahej' element={<> <SEO title="Daman To Dahej - Hindustan Taxi Service" description="Book a taxi from Daman to Dahej with Hindustan Taxi Service at affordable prices." /> <DamanToDahej /> </>} />
        <Route path='/daman-to-vasai-virar' element={<> <SEO title="Daman To Vasai Virar - Hindustan Taxi Service" description="Book a taxi from Daman to Vasai Virar with Hindustan Taxi Service at affordable prices." /> <DamanToVasaiVirar /> </>} />
        <Route path='/daman-to-bhiwandi' element={<> <SEO title="Daman To Bhiwandi - Hindustan Taxi Service" description="Book a taxi from Daman to Bhiwandi with Hindustan Taxi Service at affordable prices." /> <DamanToBhiwandi /> </>} />
        <Route path='/daman-to-badlapur' element={<> <SEO title="Daman To Badlapur - Hindustan Taxi Service" description="Book a taxi from Daman to Badlapur with Hindustan Taxi Service at affordable prices." /> <DamanToBadlapur /> </>} />









        {/* mumbai */}
        <Route path='/mumbai-to-ahemdabad' element={<> <SEO title="Mumbai To Ahmedabad - Hindustan Taxi Service" description="Book a taxi from Mumbai to Ahmedabad with Hindustan Taxi Service at affordable prices." /> <MumbaiToAhemdabad /> </>} />
        <Route path='/mumbai-to-ahmednagar' element={<> <SEO title="Mumbai To Ahmednagar - Hindustan Taxi Service" description="Book a taxi from Mumbai to Ahmednagar with Hindustan Taxi Service at affordable prices." /> <MumbaiToAhmednagar /> </>} />
        <Route path='/mumbai-to-aurangabad' element={<> <SEO title="Mumbai To Aurangabad - Hindustan Taxi Service" description="Book a taxi from Mumbai to Aurangabad with Hindustan Taxi Service at affordable prices." /> <MumbaiToAurangabad /> </>} />
        <Route path='/mumbai-to-rajkot' element={<> <SEO title="Mumbai To Rajkot - Hindustan Taxi Service" description="Book a taxi from Mumbai to Rajkot with Hindustan Taxi Service at affordable prices." /> <MumbaiToRajkot /> </>} />
        <Route path='/mumbai-to-bharuch' element={<> <SEO title="Mumbai To Bharuch - Hindustan Taxi Service" description="Book a taxi from Mumbai to Bharuch with Hindustan Taxi Service at affordable prices." /> <MumbaiToBharuch /> </>} />
        <Route path='/mumbai-to-bikaner' element={<> <SEO title="Mumbai To Bikaner - Hindustan Taxi Service" description="Book a taxi from Mumbai to Bikaner with Hindustan Taxi Service at affordable prices." /> <MumbaiToBikaner /> </>} />
        <Route path='/mumbai-to-dahej' element={<> <SEO title="Mumbai To Dahej - Hindustan Taxi Service" description="Book a taxi from Mumbai to Dahej with Hindustan Taxi Service at affordable prices." /> <MumbaiToDahej /> </>} />
        <Route path='/mumbai-to-deolali' element={<> <SEO title="Mumbai To Deolali - Hindustan Taxi Service" description="Book a taxi from Mumbai to Deolali with Hindustan Taxi Service at affordable prices." /> <MumbaiToDeolali /> </>} />
        <Route path='/mumbai-to-goa' element={<> <SEO title="Mumbai To Goa - Hindustan Taxi Service" description="Book a taxi from Mumbai to Goa with Hindustan Taxi Service at affordable prices." /> <MumbaiToGoa /> </>} />
        <Route path='/mumbai-to-pune' element={<> <SEO title="Mumbai To Pune - Hindustan Taxi Service" description="Book a taxi from Mumbai to Pune with Hindustan Taxi Service at affordable prices." /> <MumbaiToPune /> </>} />
        <Route path='/mumbai-to-satara' element={<> <SEO title="Mumbai To Satara - Hindustan Taxi Service" description="Book a taxi from Mumbai to Satara with Hindustan Taxi Service at affordable prices." /> <MumbaiToSatara /> </>} />
        <Route path='/mumbai-to-shirdi' element={<> <SEO title="Mumbai To Shirdi - Hindustan Taxi Service" description="Book a taxi from Mumbai to Shirdi with Hindustan Taxi Service at affordable prices." /> <MumbaiToShirdi /> </>} />
        <Route path='/mumbai-to-nashik' element={<> <SEO title="Mumbai To Nashik - Hindustan Taxi Service" description="Book a taxi from Mumbai to Nashik with Hindustan Taxi Service at affordable prices." /> <MumbaiToNashik /> </>} />
        <Route path='/mumbai-to-surat' element={<> <SEO title="Mumbai To Surat - Hindustan Taxi Service" description="Book a taxi from Mumbai to Surat with Hindustan Taxi Service at affordable prices." /> <MumbaiToSurat /> </>} />
        <Route path='/mumbai-to-trimbakeshwar' element={<> <SEO title="Mumbai To Trimbakeshwar - Hindustan Taxi Service" description="Book a taxi from Mumbai to Trimbakeshwar with Hindustan Taxi Service at affordable prices." /> <MumbaiToTrimbakeshwar /> </>} />




        <Route path='/mumbai-to-vadodara' element={<> <SEO title="Mumbai To Vadodara - Hindustan Taxi Service" description="Book a taxi from Mumbai to Vadodara with Hindustan Taxi Service at affordable prices." /> <MumbaiToVadodara /> </>} />
        <Route path='/mumbai-to-vapi' element={<> <SEO title="Mumbai To Vapi - Hindustan Taxi Service" description="Book a taxi from Mumbai to Vapi with Hindustan Taxi Service at affordable prices." /> <MumbaiToVapi /> </>} />
        <Route path='/mumbai-to-navsari' element={<> <SEO title="Mumbai To Navsari - Hindustan Taxi Service" description="Book a taxi from Mumbai to Navsari with Hindustan Taxi Service at affordable prices." /> <MumbaiToNavsari /> </>} />
        <Route path='/mumbai-to-lonavala' element={<> <SEO title="Mumbai To Lonavala - Hindustan Taxi Service" description="Book a taxi from Mumbai to Lonavala with Hindustan Taxi Service at affordable prices." /> <MumbaiToLonavala /> </>} />
        <Route path='/mumbai-to-khandala' element={<> <SEO title="Mumbai To Khandala - Hindustan Taxi Service" description="Book a taxi from Mumbai to Khandala with Hindustan Taxi Service at affordable prices." /> <MumbaiToKhandala /> </>} />
        <Route path='/mumbai-to-bardoli' element={<> <SEO title="Mumbai To Bardoli - Hindustan Taxi Service" description="Book a taxi from Mumbai to Bardoli with Hindustan Taxi Service at affordable prices." /> <MumbaiToBardoli /> </>} />
        <Route path='/mumbai-to-valsad' element={<> <SEO title="Mumbai To Valsad - Hindustan Taxi Service" description="Book a taxi from Mumbai to Valsad with Hindustan Taxi Service at affordable prices." /> <MumbaiToValsad /> </>} />
        <Route path='/mumbai-to-kolahpur' element={<> <SEO title="Mumbai To Kolahpur - Hindustan Taxi Service" description="Book a taxi from Mumbai to Kolahpur with Hindustan Taxi Service at affordable prices." /> <MumbaiToKolahpur /> </>} />
        <Route path='/mumbai-to-palghar' element={<> <SEO title="Mumbai To Palghar - Hindustan Taxi Service" description="Book a taxi from Mumbai to Palghar with Hindustan Taxi Service at affordable prices." /> <MumbaiToPalghar /> </>} />
        <Route path='/mumbai-to-palanpur' element={<> <SEO title="Mumbai To Palanpur - Hindustan Taxi Service" description="Book a taxi from Mumbai to Palanpur with Hindustan Taxi Service at affordable prices." /> <MumbaiToPalanpur /> </>} />
        <Route path='/mumbai-to-malegaon' element={<> <SEO title="Mumbai To Malegaon - Hindustan Taxi Service" description="Book a taxi from Mumbai to Malegaon with Hindustan Taxi Service at affordable prices." /> <MumbaiToMalegaon /> </>} />
        <Route path='/mumbai-to-jalgaon' element={<> <SEO title="Mumbai To Jalgaon - Hindustan Taxi Service" description="Book a taxi from Mumbai to Jalgaon with Hindustan Taxi Service at affordable prices." /> <MumbaiToJalgaon /> </>} />
        <Route path='/mumbai-to-baramati' element={<> <SEO title="Mumbai To Baramati - Hindustan Taxi Service" description="Book a taxi from Mumbai to Baramati with Hindustan Taxi Service at affordable prices." /> <MumbaiToBaramati /> </>} />




        <Route path='/mumbai-to-akola' element={<> <SEO title="Mumbai To Akola - Hindustan Taxi Service" description="Book a taxi from Mumbai to Akola with Hindustan Taxi Service at affordable prices." /> <MumbaiToAkola /> </>} />
        <Route path='/mumbai-to-latur' element={<> <SEO title="Mumbai To Latur - Hindustan Taxi Service" description="Book a taxi from Mumbai to Latur with Hindustan Taxi Service at affordable prices." /> <MumbaiToLatur /> </>} />
        <Route path='/mumbai-to-daman' element={<> <SEO title="Mumbai To Daman - Hindustan Taxi Service" description="Book a taxi from Mumbai to Daman with Hindustan Taxi Service at affordable prices." /> <MumbaiToDaman /> </>} />
        <Route path='/mumbai-to-osmanabad' element={<> <SEO title="Mumbai To Osmanabad - Hindustan Taxi Service" description="Book a taxi from Mumbai to Osmanabad with Hindustan Taxi Service at affordable prices." /> <MumbaiToOsmanabad /> </>} />
        <Route path='/mumbai-to-jalna' element={<> <SEO title="Mumbai To Jalna - Hindustan Taxi Service" description="Book a taxi from Mumbai to Jalna with Hindustan Taxi Service at affordable prices." /> <MumbaiToJalna /> </>} />
        <Route path='/mumbai-to-nandurbar' element={<> <SEO title="Mumbai To Nandurbar - Hindustan Taxi Service" description="Book a taxi from Mumbai to Nandurbar with Hindustan Taxi Service at affordable prices." /> <MumbaiToNandurbar /> </>} />
        <Route path='/mumbai-to-dhule' element={<> <SEO title="Mumbai To Dhule - Hindustan Taxi Service" description="Book a taxi from Mumbai to Dhule with Hindustan Taxi Service at affordable prices." /> <MumbaiToDhule /> </>} />
        <Route path='/mumbai-to-khopoli' element={<> <SEO title="Mumbai To Khopoli - Hindustan Taxi Service" description="Book a taxi from Mumbai to Khopoli with Hindustan Taxi Service at affordable prices." /> <MumbaiToKhopoli /> </>} />
        <Route path='/mumbai-to-shirpur' element={<> <SEO title="Mumbai To Shirpur - Hindustan Taxi Service" description="Book a taxi from Mumbai to Shirpur with Hindustan Taxi Service at affordable prices." /> <MumbaiToShirpur /> </>} />
        <Route path='/mumbai-to-alibag' element={<> <SEO title="Mumbai To Alibag - Hindustan Taxi Service" description="Book a taxi from Mumbai to Alibag with Hindustan Taxi Service at affordable prices." /> <MumbaiToAlibag /> </>} />
        <Route path='/mumbai-to-ankleshwar' element={<> <SEO title="Mumbai To Ankleshwar - Hindustan Taxi Service" description="Book a taxi from Mumbai to Ankleshwar with Hindustan Taxi Service at affordable prices." /> <MumbaiToAnkleshwar /> </>} />
        <Route path='/mumbai-to-veraval' element={<> <SEO title="Mumbai To Veraval - Hindustan Taxi Service" description="Book a taxi from Mumbai to Veraval with Hindustan Taxi Service at affordable prices." /> <MumbaiToVeraval /> </>} />
        <Route path='/mumbai-to-virar' element={<> <SEO title="Mumbai To Virar - Hindustan Taxi Service" description="Book a taxi from Mumbai to Virar with Hindustan Taxi Service at affordable prices." /> <MumbaiToVirar /> </>} />
        <Route path='/mumbai-to-matheran' element={<> <SEO title="Mumbai To Matheran - Hindustan Taxi Service" description="Book a taxi from Mumbai to Matheran with Hindustan Taxi Service at affordable prices." /> <MumbaiToMatheran /> </>} />
        <Route path='/mumbai-to-indore' element={<> <SEO title="Mumbai To Indore - Hindustan Taxi Service" description="Book a taxi from Mumbai to Indore with Hindustan Taxi Service at affordable prices." /> <MumbaiToIndore /> </>} />
        <Route path='/mumbai-to-nagpur' element={<> <SEO title="Mumbai To Nagpur - Hindustan Taxi Service" description="Book a taxi from Mumbai to Nagpur with Hindustan Taxi Service at affordable prices." /> <MumbaiToNagpur /> </>} />
        <Route path='/mumbai-to-solapur' element={<> <SEO title="Mumbai To Solapur - Hindustan Taxi Service" description="Book a taxi from Mumbai to Solapur with Hindustan Taxi Service at affordable prices." /> <MumbaiToSolapur /> </>} />
        <Route path='/mumbai-to-silvassa' element={<> <SEO title="Mumbai To Silvassa - Hindustan Taxi Service" description="Book a taxi from Mumbai to Silvassa with Hindustan Taxi Service at affordable prices." /> <MumbaiToSilvassa /> </>} />




        <Route path='/mumbai-to-bhuj' element={<> <SEO title="Mumbai To Bhuj - Hindustan Taxi Service" description="Book a taxi from Mumbai to Bhuj with Hindustan Taxi Service at affordable prices." /> <MumbaiToBhuj /> </>} />
        <Route path='/mumbai-to-udaipur' element={<> <SEO title="Mumbai To Udaipur - Hindustan Taxi Service" description="Book a taxi from Mumbai to Udaipur with Hindustan Taxi Service at affordable prices." /> <MumbaiToUdaipur /> </>} />
        <Route path='/mumbai-to-jamnagar' element={<> <SEO title="Mumbai To Jamnagar - Hindustan Taxi Service" description="Book a taxi from Mumbai to Jamnagar with Hindustan Taxi Service at affordable prices." /> <MumbaiToJamnagar /> </>} />
        <Route path='/mumbai-to-bhavnagar' element={<> <SEO title="Mumbai To Bhavnagar - Hindustan Taxi Service" description="Book a taxi from Mumbai to Bhavnagar with Hindustan Taxi Service at affordable prices." /> <MumbaiToBhavnagar /> </>} />
        <Route path='/mumbaicentral-to-surat' element={<> <SEO title="Mumbai Central To Surat - Hindustan Taxi Service" description="Book a taxi from Mumbai Central to Surat with Hindustan Taxi Service at affordable prices." /> <MumbaiCentralToSurat /> </>} />
        <Route path='/mumbai-to-surat-sedan' element={<> <SEO title="Mumbai To Surat Sedan - Hindustan Taxi Service" description="Book a sedan taxi from Mumbai to Surat with Hindustan Taxi Service at affordable prices." /> <MumbaiToSuratSedan /> </>} />
        <Route path='/mumbai-to-surat-innovacrysta' element={<> <SEO title="Mumbai To Surat Innova Crysta - Hindustan Taxi Service" description="Book an Innova Crysta taxi from Mumbai to Surat with Hindustan Taxi Service at affordable prices." /> <MumbaiToSuratInnovaCrysta /> </>} />
        <Route path='/mumbai-to-surat-tempo' element={<> <SEO title="Mumbai To Surat Tempo - Hindustan Taxi Service" description="Book a tempo taxi from Mumbai to Surat with Hindustan Taxi Service at affordable prices." /> <MumbaiToSuratTempo /> </>} />
        <Route path='/mumbai-to-surat-innova' element={<> <SEO title="Mumbai To Surat Innova - Hindustan Taxi Service" description="Book an Innova taxi from Mumbai to Surat with Hindustan Taxi Service at affordable prices." /> <MumbaiToSuratInnova /> </>} />
        <Route path='/mumbai-to-satara' element={<> <SEO title="Mumbai To Satara - Hindustan Taxi Service" description="Book a taxi from Mumbai to Satara with Hindustan Taxi Service at affordable prices." /> <MumbaiToSatara /> </>} />
        <Route path='/mumbai-to-badlapur' element={<> <SEO title="Mumbai To Badlapur - Hindustan Taxi Service" description="Book a taxi from Mumbai to Badlapur with Hindustan Taxi Service at affordable prices." /> <MumbaiToBadlapur /> </>} />
        <Route path='/mumbai-to-bhimashankar' element={<> <SEO title="Mumbai To Bhimashankar - Hindustan Taxi Service" description="Book a taxi from Mumbai to Bhimashankar with Hindustan Taxi Service at affordable prices." /> <MumbaiToBhimashankar /> </>} />
        <Route path='/mumbai-to-umargam' element={<> <SEO title="Mumbai To Umargam - Hindustan Taxi Service" description="Book a taxi from Mumbai to Umargam with Hindustan Taxi Service at affordable prices." /> <MumbaiToUmargam /> </>} />
        <Route path='/mumbai-to-parbhani' element={<> <SEO title="Mumbai To Parbhani - Hindustan Taxi Service" description="Book a taxi from Mumbai to Parbhani with Hindustan Taxi Service at affordable prices." /> <MumbaiToParbhani /> </>} />
        <Route path='/mumbai-to-kopargaon' element={<> <SEO title="Mumbai To Kopargaon - Hindustan Taxi Service" description="Book a taxi from Mumbai to Kopargaon with Hindustan Taxi Service at affordable prices." /> <MumbaiToKopargaon /> </>} />
        <Route path='/mumbai-to-mahad' element={<> <SEO title="Mumbai To Mahad - Hindustan Taxi Service" description="Book a taxi from Mumbai to Mahad with Hindustan Taxi Service at affordable prices." /> <MumbaiToMahad /> </>} />
        <Route path='/mumbai-to-jabalpur' element={<> <SEO title="Mumbai To Jabalpur - Hindustan Taxi Service" description="Book a taxi from Mumbai to Jabalpur with Hindustan Taxi Service at affordable prices." /> <MumbaiToJabalpur /> </>} />
        <Route path='/mumbai-to-ambernath' element={<> <SEO title="Mumbai To Ambernath - Hindustan Taxi Service" description="Book a taxi from Mumbai to Ambernath with Hindustan Taxi Service at affordable prices." /> <MumbaiToAmbernath /> </>} />
        <Route path='/mumbai-to-padgha' element={<> <SEO title="Mumbai To Padgha - Hindustan Taxi Service" description="Book a taxi from Mumbai to Padgha with Hindustan Taxi Service at affordable prices." /> <MumbaiToPadgha /> </>} />
        <Route path='/mumbai-to-bhilad' element={<> <SEO title="Mumbai To Bhilad - Hindustan Taxi Service" description="Book a taxi from Mumbai to Bhilad with Hindustan Taxi Service at affordable prices." /> <MumbaiToBhilad /> </>} />
        <Route path='/mumbai-to-vikramgad' element={<> <SEO title="Mumbai To Vikramgad - Hindustan Taxi Service" description="Book a taxi from Mumbai to Vikramgad with Hindustan Taxi Service at affordable prices." /> <MumbaiToVikramgad /> </>} />
        <Route path='/mumbai-to-vajreshwari' element={<> <SEO title="Mumbai To Vajreshwari - Hindustan Taxi Service" description="Book a taxi from Mumbai to Vajreshwari with Hindustan Taxi Service at affordable prices." /> <MumbaiToVajreshwari /> </>} />
        <Route path='/mumbai-to-chandwad' element={<> <SEO title="Mumbai To Chandwad - Hindustan Taxi Service" description="Book a taxi from Mumbai to Chandwad with Hindustan Taxi Service at affordable prices." /> <MumbaiToChandwad /> </>} />
        <Route path='/mumbai-to-gandhinagar' element={<> <SEO title="Mumbai To Gandhinagar - Hindustan Taxi Service" description="Book a taxi from Mumbai to Gandhinagar with Hindustan Taxi Service at affordable prices." /> <MumbaiToGandhinagar /> </>} />
        <Route path='/mumbai-to-hazira' element={<> <SEO title="Mumbai To Hazira - Hindustan Taxi Service" description="Book a taxi from Mumbai to Hazira with Hindustan Taxi Service at affordable prices." /> <MumbaiToHazira /> </>} />
        <Route path='/mumbai-to-nadiad' element={<> <SEO title="Mumbai To Nadiad - Hindustan Taxi Service" description="Book a taxi from Mumbai to Nadiad with Hindustan Taxi Service at affordable prices." /> <MumbaiToNadiad /> </>} />



        <Route path='/mumbai-to-dharampur' element={<> <SEO title="Mumbai To Dharampur - Hindustan Taxi Service" description="Book a taxi from Mumbai to Dharampur with Hindustan Taxi Service at affordable prices." /> <MumbaiToDharampur /> </>} />
        <Route path='/mumbai-to-karjat' element={<> <SEO title="Mumbai To Karjat - Hindustan Taxi Service" description="Book a taxi from Mumbai to Karjat with Hindustan Taxi Service at affordable prices." /> <MumbaiToKarjat /> </>} />
        <Route path='/mumbai-to-ulhasnagar' element={<> <SEO title="Mumbai To Ulhasnagar - Hindustan Taxi Service" description="Book a taxi from Mumbai to Ulhasnagar with Hindustan Taxi Service at affordable prices." /> <MumbaiToUlhasnagar /> </>} />
        <Route path='/mumbai-to-boisar' element={<> <SEO title="Mumbai To Boisar - Hindustan Taxi Service" description="Book a taxi from Mumbai to Boisar with Hindustan Taxi Service at affordable prices." /> <MumbaiToBoisar /> </>} />
        <Route path='/mumbai-to-kheda' element={<> <SEO title="Mumbai To Kheda - Hindustan Taxi Service" description="Book a taxi from Mumbai to Kheda with Hindustan Taxi Service at affordable prices." /> <MumbaiToKheda /> </>} />
        <Route path='/mumbai-to-saputara' element={<> <SEO title="Mumbai To Saputara - Hindustan Taxi Service" description="Book a taxi from Mumbai to Saputara with Hindustan Taxi Service at affordable prices." /> <MumbaiToSaputara /> </>} />
        <Route path='/mumbai-to-gandevi' element={<> <SEO title="Mumbai To Gandevi - Hindustan Taxi Service" description="Book a taxi from Mumbai to Gandevi with Hindustan Taxi Service at affordable prices." /> <MumbaiToGandevi /> </>} />
        <Route path='/mumbai-to-bhusawal' element={<> <SEO title="Mumbai To Bhusawal - Hindustan Taxi Service" description="Book a taxi from Mumbai to Bhusawal with Hindustan Taxi Service at affordable prices." /> <MumbaiToBhusawal /> </>} />
        <Route path='/mumbai-to-jawhar' element={<> <SEO title="Mumbai To Jawhar - Hindustan Taxi Service" description="Book a taxi from Mumbai to Jawhar with Hindustan Taxi Service at affordable prices." /> <MumbaiToJawhar /> </>} />
        <Route path='/mumbai-to-tarapur' element={<> <SEO title="Mumbai To Tarapur - Hindustan Taxi Service" description="Book a taxi from Mumbai to Tarapur with Hindustan Taxi Service at affordable prices." /> <MumbaiToTarapur /> </>} />


        <Route path='/mumbai-to-murbad' element={<> <SEO title="Mumbai To Murbad - Hindustan Taxi Service" description="Book a taxi from Mumbai to Murbad with Hindustan Taxi Service at affordable prices." /> <MumbaiToMurbad /> </>} />
        <Route path='/mumbai-to-anjangaon' element={<> <SEO title="Mumbai To Anjangaon - Hindustan Taxi Service" description="Book a taxi from Mumbai to Anjangaon with Hindustan Taxi Service at affordable prices." /> <MumbaiToAnjangaon /> </>} />
        <Route path='/mumbai-to-jalna' element={<> <SEO title="Mumbai To Jalna - Hindustan Taxi Service" description="Book a taxi from Mumbai to Jalna with Hindustan Taxi Service at affordable prices." /> <MumbaiToJalna /> </>} />
        <Route path='/mumbai-to-vyara' element={<> <SEO title="Mumbai To Vyara - Hindustan Taxi Service" description="Book a taxi from Mumbai to Vyara with Hindustan Taxi Service at affordable prices." /> <MumbaiToVyara /> </>} />
        <Route path='/mumbai-to-sangamner' element={<> <SEO title="Mumbai To Sangamner - Hindustan Taxi Service" description="Book a taxi from Mumbai to Sangamner with Hindustan Taxi Service at affordable prices." /> <MumbaiToSangamner /> </>} />
        <Route path='/mumbai-to-ratnagiri' element={<> <SEO title="Mumbai To Ratnagiri - Hindustan Taxi Service" description="Book a taxi from Mumbai to Ratnagiri with Hindustan Taxi Service at affordable prices." /> <MumbaiToRatnagiri /> </>} />
        <Route path='/mumbai-to-sangli' element={<> <SEO title="Mumbai To Sangli - Hindustan Taxi Service" description="Book a taxi from Mumbai to Sangli with Hindustan Taxi Service at affordable prices." /> <MumbaiToSangli /> </>} />
        <Route path='/mumbai-to-hyderabad' element={<> <SEO title="Mumbai To Hyderabad - Hindustan Taxi Service" description="Book a taxi from Mumbai to Hyderabad with Hindustan Taxi Service at affordable prices." /> <MumbaiToHyderabad /> </>} />
        <Route path='/mumbai-to-pardi' element={<> <SEO title="Mumbai To Pardi - Hindustan Taxi Service" description="Book a taxi from Mumbai to Pardi with Hindustan Taxi Service at affordable prices." /> <MumbaiToPardi /> </>} />
        <Route path='/mumbai-to-chakan' element={<> <SEO title="Mumbai To Chakan - Hindustan Taxi Service" description="Book a taxi from Mumbai to Chakan with Hindustan Taxi Service at affordable prices." /> <MumbaiToChakan /> </>} />
        <Route path='/mumbai-to-rasayani' element={<> <SEO title="Mumbai To Rasayani - Hindustan Taxi Service" description="Book a taxi from Mumbai to Rasayani with Hindustan Taxi Service at affordable prices." /> <MumbaiToRasayani /> </>} />
        <Route path='/mumbai-to-wada' element={<> <SEO title="Mumbai To Wada - Hindustan Taxi Service" description="Book a taxi from Mumbai to Wada with Hindustan Taxi Service at affordable prices." /> <MumbaiToWada /> </>} />
        <Route path='/mumbai-to-kasara' element={<> <SEO title="Mumbai To Kasara - Hindustan Taxi Service" description="Book a taxi from Mumbai to Kasara with Hindustan Taxi Service at affordable prices." /> <MumbaiToKasara /> </>} />
        <Route path='/mumbai-to-shahapur' element={<> <SEO title="Mumbai To Shahapur - Hindustan Taxi Service" description="Book a taxi from Mumbai to Shahapur with Hindustan Taxi Service at affordable prices." /> <MumbaiToShahapur /> </>} />
        <Route path='/mumbai-to-nagpur' element={<> <SEO title="Mumbai To Nagpur - Hindustan Taxi Service" description="Book a taxi from Mumbai to Nagpur with Hindustan Taxi Service at affordable prices." /> <MumbaiToNagpur /> </>} />
        <Route path='/mumbai-to-nandurbar' element={<> <SEO title="Mumbai To Nandurbar - Hindustan Taxi Service" description="Book a taxi from Mumbai to Nandurbar with Hindustan Taxi Service at affordable prices." /> <MumbaiToNandurbar /> </>} />



        <Route path='/mumbai-to-nagothane' element={<> <SEO title="Mumbai To Nagothane - Hindustan Taxi Service" description="Book a taxi from Mumbai to Nagothane with Hindustan Taxi Service at affordable prices." /> <MumbaiToNagothane /> </>} />
        <Route path='/mumbai-to-sinnar' element={<> <SEO title="Mumbai To Sinnar - Hindustan Taxi Service" description="Book a taxi from Mumbai to Sinnar with Hindustan Taxi Service at affordable prices." /> <MumbaiToSinnar /> </>} />
        <Route path='/mumbai-to-beed' element={<> <SEO title="Mumbai To Beed - Hindustan Taxi Service" description="Book a taxi from Mumbai to Beed with Hindustan Taxi Service at affordable prices." /> <MumbaiToBeed /> </>} />
        <Route path='/mumbai-to-igatpuri' element={<> <SEO title="Mumbai To Igatpuri - Hindustan Taxi Service" description="Book a taxi from Mumbai to Igatpuri with Hindustan Taxi Service at affordable prices." /> <MumbaiToIgatpuri /> </>} />
        <Route path='/mumbai-to-bhopal' element={<> <SEO title="Mumbai To Bhopal - Hindustan Taxi Service" description="Book a taxi from Mumbai to Bhopal with Hindustan Taxi Service at affordable prices." /> <MumbaiToBhopal /> </>} />
        <Route path='/mumbai-to-dahanu' element={<> <SEO title="Mumbai To Dahanu - Hindustan Taxi Service" description="Book a taxi from Mumbai to Dahanu with Hindustan Taxi Service at affordable prices." /> <MumbaiToDahanu /> </>} />
        <Route path='/mumbai-to-bilimora' element={<> <SEO title="Mumbai To Bilimora - Hindustan Taxi Service" description="Book a taxi from Mumbai to Bilimora with Hindustan Taxi Service at affordable prices." /> <MumbaiToBilimora /> </>} />
        <Route path='/mumbai-to-manmad' element={<> <SEO title="Mumbai To Manmad - Hindustan Taxi Service" description="Book a taxi from Mumbai to Manmad with Hindustan Taxi Service at affordable prices." /> <MumbaiToManmad /> </>} />
        <Route path='/mumbai-to-bengaluru' element={<> <SEO title="Mumbai To Bengaluru - Hindustan Taxi Service" description="Book a taxi from Mumbai to Bengaluru with Hindustan Taxi Service at affordable prices." /> <MumbaiToBengaluru /> </>} />
        <Route path='/mumbai-to-chikhali' element={<> <SEO title="Mumbai To Chikhali - Hindustan Taxi Service" description="Book a taxi from Mumbai to Chikhali with Hindustan Taxi Service at affordable prices." /> <MumbaiToChikhali /> </>} />
        <Route path='/mumbai-to-manor' element={<> <SEO title="Mumbai To Manor - Hindustan Taxi Service" description="Book a taxi from Mumbai to Manor with Hindustan Taxi Service at affordable prices." /> <MumbaiToManor /> </>} />
        <Route path='/mumbai-to-lasalgaon' element={<> <SEO title="Mumbai To Lasalgaon - Hindustan Taxi Service" description="Book a taxi from Mumbai to Lasalgaon with Hindustan Taxi Service at affordable prices." /> <MumbaiToLasalgaon /> </>} />
        <Route path='/mumbai-to-sakri' element={<> <SEO title="Mumbai To Sakri - Hindustan Taxi Service" description="Book a taxi from Mumbai to Sakri with Hindustan Taxi Service at affordable prices." /> <MumbaiToSakri /> </>} />
        <Route path='/mumbai-to-udvada' element={<> <SEO title="Mumbai To Udvada - Hindustan Taxi Service" description="Book a taxi from Mumbai to Udvada with Hindustan Taxi Service at affordable prices." /> <MumbaiToUdvada /> </>} />



        {/* vapi */}
        <Route path='/vapi-to-mumbai' element={<> <SEO title="Vapi To Mumbai - Hindustan Taxi Service" description="Book a taxi from Vapi to Mumbai with Hindustan Taxi Service at affordable prices." /> <VapiToMumbai /> </>} />
        <Route path='/vapi-to-mumbaiairport' element={<> <SEO title="Vapi To Mumbai Airport - Hindustan Taxi Service" description="Book a taxi from Vapi to Mumbai Airport with Hindustan Taxi Service at affordable prices." /> <VapiToMumbaiAirport /> </>} />
        <Route path='/vapi-to-navimumbai' element={<> <SEO title="Vapi To Navi Mumbai - Hindustan Taxi Service" description="Book a taxi from Vapi to Navi Mumbai with Hindustan Taxi Service at affordable prices." /> <VapiToNaviMumbai /> </>} />
        <Route path='/vapi-to-daman' element={<> <SEO title="Vapi To Daman - Hindustan Taxi Service" description="Book a taxi from Vapi to Daman with Hindustan Taxi Service at affordable prices." /> <VapiToDaman /> </>} />
        <Route path='/vapi-to-ahmedabad' element={<> <SEO title="Vapi To Ahmedabad - Hindustan Taxi Service" description="Book a taxi from Vapi to Ahmedabad with Hindustan Taxi Service at affordable prices." /> <VapiToAhmedabad /> </>} />
        <Route path='/vapi-to-nagpur' element={<> <SEO title="Vapi To Nagpur - Hindustan Taxi Service" description="Book a taxi from Vapi to Nagpur with Hindustan Taxi Service at affordable prices." /> <VapiToNagpur /> </>} />
        <Route path='/vapi-to-solapur' element={<> <SEO title="Vapi To Solapur - Hindustan Taxi Service" description="Book a taxi from Vapi to Solapur with Hindustan Taxi Service at affordable prices." /> <VapiToSolapur /> </>} />
        <Route path='/vapi-to-surat' element={<> <SEO title="Vapi To Surat - Hindustan Taxi Service" description="Book a taxi from Vapi to Surat with Hindustan Taxi Service at affordable prices." /> <VapiToSurat /> </>} />
        <Route path='/vapi-to-thane' element={<> <SEO title="Vapi To Thane - Hindustan Taxi Service" description="Book a taxi from Vapi to Thane with Hindustan Taxi Service at affordable prices." /> <VapiToThane /> </>} />
        <Route path='/vapi-to-nashik' element={<> <SEO title="Vapi To Nashik - Hindustan Taxi Service" description="Book a taxi from Vapi to Nashik with Hindustan Taxi Service at affordable prices." /> <VapiToNashik /> </>} />
        <Route path='/vapi-to-pune' element={<> <SEO title="Vapi To Pune - Hindustan Taxi Service" description="Book a taxi from Vapi to Pune with Hindustan Taxi Service at affordable prices." /> <VapiToPune /> </>} />
        <Route path='/vapi-to-shirdi' element={<> <SEO title="Vapi To Shirdi - Hindustan Taxi Service" description="Book a taxi from Vapi to Shirdi with Hindustan Taxi Service at affordable prices." /> <VapiToShirdi /> </>} />
        <Route path='/vapi-to-bhiwandi' element={<> <SEO title="Vapi To Bhiwandi - Hindustan Taxi Service" description="Book a taxi from Vapi to Bhiwandi with Hindustan Taxi Service at affordable prices." /> <VapiToBhiwandi /> </>} />
        <Route path='/vapi-to-badlapur' element={<> <SEO title="Vapi To Badlapur - Hindustan Taxi Service" description="Book a taxi from Vapi to Badlapur with Hindustan Taxi Service at affordable prices." /> <VapiToBadlapur /> </>} />
        <Route path='/vapi-to-dadar' element={<> <SEO title="Vapi To Dadar - Hindustan Taxi Service" description="Book a taxi from Vapi to Dadar with Hindustan Taxi Service at affordable prices." /> <VapiToDadar /> </>} />
        <Route path='/vapi-to-panvel' element={<> <SEO title="Vapi To Panvel - Hindustan Taxi Service" description="Book a taxi from Vapi to Panvel with Hindustan Taxi Service at affordable prices." /> <VapiToPanvel /> </>} />
        <Route path='/vapi-to-mumbaicentral' element={<> <SEO title="Vapi To Mumbai Central - Hindustan Taxi Service" description="Book a taxi from Vapi to Mumbai Central with Hindustan Taxi Service at affordable prices." /> <VapiToMumbaiCentral /> </>} />
        <Route path='/vapi-to-rajkot' element={<> <SEO title="Vapi To Rajkot - Hindustan Taxi Service" description="Book a taxi from Vapi to Rajkot with Hindustan Taxi Service at affordable prices." /> <VapiToRajkot /> </>} />
        <Route path='/vapi-to-bhuj' element={<> <SEO title="Vapi To Bhuj - Hindustan Taxi Service" description="Book a taxi from Vapi to Bhuj with Hindustan Taxi Service at affordable prices." /> <VapiToBhuj /> </>} />
        <Route path='/vapi-to-jamnagar' element={<> <SEO title="Vapi To Jamnagar - Hindustan Taxi Service" description="Book a taxi from Vapi to Jamnagar with Hindustan Taxi Service at affordable prices." /> <VapiToJamnagar /> </>} />
        <Route path='/vapi-to-udaipur' element={<> <SEO title="Vapi To Udaipur - Hindustan Taxi Service" description="Book a taxi from Vapi to Udaipur with Hindustan Taxi Service at affordable prices." /> <VapiToUdaipur /> </>} />
        <Route path='/vapi-to-indore' element={<> <SEO title="Vapi To Indore - Hindustan Taxi Service" description="Book a taxi from Vapi to Indore with Hindustan Taxi Service at affordable prices." /> <VapiToIndore /> </>} />
        <Route path='/vapi-to-goa' element={<> <SEO title="Vapi To Goa - Hindustan Taxi Service" description="Book a taxi from Vapi to Goa with Hindustan Taxi Service at affordable prices." /> <VapiToGoa /> </>} />
        <Route path='/vapi-to-lonavala' element={<> <SEO title="Vapi To Lonavala - Hindustan Taxi Service" description="Book a taxi from Vapi to Lonavala with Hindustan Taxi Service at affordable prices." /> <VapiToLonavala /> </>} />
        <Route path='/vapi-to-anand' element={<> <SEO title="Vapi To Anand - Hindustan Taxi Service" description="Book a taxi from Vapi to Anand with Hindustan Taxi Service at affordable prices." /> <VapiToAnand /> </>} />
        <Route path='/vapi-to-bharuch' element={<> <SEO title="Vapi To Bharuch - Hindustan Taxi Service" description="Book a taxi from Vapi to Bharuch with Hindustan Taxi Service at affordable prices." /> <VapiToBharuch /> </>} />
        <Route path='/vapi-to-vadodara' element={<> <SEO title="Vapi To Vadodara - Hindustan Taxi Service" description="Book a taxi from Vapi to Vadodara with Hindustan Taxi Service at affordable prices." /> <VapiToVadodara /> </>} />



        {/* valsad */}
        <Route path='/valsad-to-ahemdabd' element={<> <SEO title="Valsad To Ahmedabad - Hindustan Taxi Service" description="Book a taxi from Valsad to Ahmedabad with Hindustan Taxi Service at affordable prices." /> <ValsadToAhemdabad /> </>} />
        <Route path='/valsad-to-bhopal' element={<> <SEO title="Valsad To Bhopal - Hindustan Taxi Service" description="Book a taxi from Valsad to Bhopal with Hindustan Taxi Service at affordable prices." /> <ValsadToBhopal /> </>} />
        <Route path='/valsad-to-daman' element={<> <SEO title="Valsad To Daman - Hindustan Taxi Service" description="Book a taxi from Valsad to Daman with Hindustan Taxi Service at affordable prices." /> <ValsadToDaman /> </>} />
        <Route path='/valsad-to-goa' element={<> <SEO title="Valsad To Goa - Hindustan Taxi Service" description="Book a taxi from Valsad to Goa with Hindustan Taxi Service at affordable prices." /> <ValsadToGoa /> </>} />
        <Route path='/valsad-to-hazira' element={<> <SEO title="Valsad To Hazira - Hindustan Taxi Service" description="Book a taxi from Valsad to Hazira with Hindustan Taxi Service at affordable prices." /> <ValsadToHazira /> </>} />
        <Route path='/valsad-to-indore' element={<> <SEO title="Valsad To Indore - Hindustan Taxi Service" description="Book a taxi from Valsad to Indore with Hindustan Taxi Service at affordable prices." /> <ValsadToIndore /> </>} />
        <Route path='/valsad-to-lonavala' element={<> <SEO title="Valsad To Lonavala - Hindustan Taxi Service" description="Book a taxi from Valsad to Lonavala with Hindustan Taxi Service at affordable prices." /> <ValsadToLonavala /> </>} />
        <Route path='/valsad-to-mumbai' element={<> <SEO title="Valsad To Mumbai - Hindustan Taxi Service" description="Book a taxi from Valsad to Mumbai with Hindustan Taxi Service at affordable prices." /> <ValsadToMumbai /> </>} />
        <Route path='/valsad-to-navsari' element={<> <SEO title="Valsad To Navsari - Hindustan Taxi Service" description="Book a taxi from Valsad to Navsari with Hindustan Taxi Service at affordable prices." /> <ValsadToNavsari /> </>} />
        <Route path='/valsad-to-nashik' element={<> <SEO title="Valsad To Nashik - Hindustan Taxi Service" description="Book a taxi from Valsad to Nashik with Hindustan Taxi Service at affordable prices." /> <ValsadToNashik /> </>} />
        <Route path='/valsad-to-pune' element={<> <SEO title="Valsad To Pune - Hindustan Taxi Service" description="Book a taxi from Valsad to Pune with Hindustan Taxi Service at affordable prices." /> <ValsadToPune /> </>} />
        <Route path='/valsad-to-shirdi' element={<> <SEO title="Valsad To Shirdi - Hindustan Taxi Service" description="Book a taxi from Valsad to Shirdi with Hindustan Taxi Service at affordable prices." /> <ValsadToShirdi /> </>} />
        <Route path='/valsad-to-silvassa' element={<> <SEO title="Valsad To Silvassa - Hindustan Taxi Service" description="Book a taxi from Valsad to Silvassa with Hindustan Taxi Service at affordable prices." /> <ValsadToSilvassa /> </>} />
        <Route path='/valsad-to-surat' element={<> <SEO title="Valsad To Surat - Hindustan Taxi Service" description="Book a taxi from Valsad to Surat with Hindustan Taxi Service at affordable prices." /> <ValsadToSurat /> </>} />
        <Route path='/valsad-to-ujjain' element={<> <SEO title="Valsad To Ujjain - Hindustan Taxi Service" description="Book a taxi from Valsad to Ujjain with Hindustan Taxi Service at affordable prices." /> <ValsadToUjjain /> </>} />
        <Route path='/valsad-to-vadodara' element={<> <SEO title="Valsad To Vadodara - Hindustan Taxi Service" description="Book a taxi from Valsad to Vadodara with Hindustan Taxi Service at affordable prices." /> <ValsadToVadodara /> </>} />
        <Route path='/valsad-to-vapi' element={<> <SEO title="Valsad To Vapi - Hindustan Taxi Service" description="Book a taxi from Valsad to Vapi with Hindustan Taxi Service at affordable prices." /> <ValsadToVapi /> </>} />




        {/* amreli */}
        <Route path='/amreli-to-surat' element={<> <SEO title="Amreli To Surat - Hindustan Taxi Service" description="Book a taxi from Amreli to Surat with Hindustan Taxi Service at affordable prices." /> <AmreliToSurat /> </>} />
        <Route path='/amreli-to-babra' element={<> <SEO title="Amreli To Babra - Hindustan Taxi Service" description="Book a taxi from Amreli to Babra with Hindustan Taxi Service at affordable prices." /> <AmreliToBabra /> </>} />
        <Route path='/amreli-to-rajula' element={<> <SEO title="Amreli To Rajula - Hindustan Taxi Service" description="Book a taxi from Amreli to Rajula with Hindustan Taxi Service at affordable prices." /> <AmreliToRajula /> </>} />
        <Route path='/amreli-to-rajkot' element={<> <SEO title="Amreli To Rajkot - Hindustan Taxi Service" description="Book a taxi from Amreli to Rajkot with Hindustan Taxi Service at affordable prices." /> <AmreliToRajkot /> </>} />
        <Route path='/amreli-to-pune' element={<> <SEO title="Amreli To Pune - Hindustan Taxi Service" description="Book a taxi from Amreli to Pune with Hindustan Taxi Service at affordable prices." /> <AmreliToPune /> </>} />
        <Route path='/amreli-to-ahmedabadairport' element={<> <SEO title="Amreli To Ahmedabad Airport - Hindustan Taxi Service" description="Book a taxi from Amreli to Ahmedabad Airport with Hindustan Taxi Service at affordable prices." /> <AmreliToAhmedabadAirport /> </>} />
        <Route path='/amreli-to-dhari' element={<> <SEO title="Amreli To Dhari - Hindustan Taxi Service" description="Book a taxi from Amreli to Dhari with Hindustan Taxi Service at affordable prices." /> <AmreliToDhari /> </>} />
        <Route path='/amreli-to-jamnagar' element={<> <SEO title="Amreli To Jamnagar - Hindustan Taxi Service" description="Book a taxi from Amreli to Jamnagar with Hindustan Taxi Service at affordable prices." /> <AmreliToJamnagar /> </>} />
        <Route path='/amreli-to-vadodara' element={<> <SEO title="Amreli To Vadodara - Hindustan Taxi Service" description="Book a taxi from Amreli to Vadodara with Hindustan Taxi Service at affordable prices." /> <AmreliToVadodara /> </>} />
        <Route path='/amreli-to-navsari' element={<> <SEO title="Amreli To Navsari - Hindustan Taxi Service" description="Book a taxi from Amreli to Navsari with Hindustan Taxi Service at affordable prices." /> <AmreliToNavsari /> </>} />
        <Route path='/amreli-to-diu' element={<> <SEO title="Amreli To Diu - Hindustan Taxi Service" description="Book a taxi from Amreli to Diu with Hindustan Taxi Service at affordable prices." /> <AmreliToDiu /> </>} />
        <Route path='/amreli-to-chotila' element={<> <SEO title="Amreli To Chotila - Hindustan Taxi Service" description="Book a taxi from Amreli to Chotila with Hindustan Taxi Service at affordable prices." /> <AmreliToChotila /> </>} />
        <Route path='/amreli-to-dwarka' element={<> <SEO title="Amreli To Dwarka - Hindustan Taxi Service" description="Book a taxi from Amreli to Dwarka with Hindustan Taxi Service at affordable prices." /> <AmreliToDwarka /> </>} />
        <Route path='/amreli-to-goa' element={<> <SEO title="Amreli To Goa - Hindustan Taxi Service" description="Book a taxi from Amreli to Goa with Hindustan Taxi Service at affordable prices." /> <AmreliToGoa /> </>} />
        <Route path='/amreli-to-ramdevra' element={<> <SEO title="Amreli To Ramdevra - Hindustan Taxi Service" description="Book a taxi from Amreli to Ramdevra with Hindustan Taxi Service at affordable prices." /> <AmreliToRamdevra /> </>} />
        <Route path='/amreli-to-veraval' element={<> <SEO title="Amreli To Veraval - Hindustan Taxi Service" description="Book a taxi from Amreli to Veraval with Hindustan Taxi Service at affordable prices." /> <AmreliToVeraval /> </>} />
        <Route path='/amreli-to-junagadh' element={<> <SEO title="Amreli To Junagadh - Hindustan Taxi Service" description="Book a taxi from Amreli to Junagadh with Hindustan Taxi Service at affordable prices." /> <AmreliToJunagadh /> </>} />
        <Route path='/amreli-to-ahmedabad' element={<> <SEO title="Amreli To Ahmedabad - Hindustan Taxi Service" description="Book a taxi from Amreli to Ahmedabad with Hindustan Taxi Service at affordable prices." /> <AmreliToAhmedabad /> </>} />
        <Route path='/amreli-to-rajkotairport' element={<> <SEO title="Amreli To Rajkot Airport - Hindustan Taxi Service" description="Book a taxi from Amreli to Rajkot Airport with Hindustan Taxi Service at affordable prices." /> <AmreliToRajkotAirport /> </>} />
        <Route path='/amreli-to-bhavnagar' element={<> <SEO title="Amreli To Bhavnagar - Hindustan Taxi Service" description="Book a taxi from Amreli to Bhavnagar with Hindustan Taxi Service at affordable prices." /> <AmreliToBhavnagar /> </>} />
        <Route path='/amreli-to-palitana' element={<> <SEO title="Amreli To Palitana - Hindustan Taxi Service" description="Book a taxi from Amreli to Palitana with Hindustan Taxi Service at affordable prices." /> <AmreliToPalitana /> </>} />
        <Route path='/amreli-to-savarkundla' element={<> <SEO title="Amreli To Savarkundla - Hindustan Taxi Service" description="Book a taxi from Amreli to Savarkundla with Hindustan Taxi Service at affordable prices." /> <AmreliToSavarkundla /> </>} />
        <Route path='/amreli-to-mumbai' element={<> <SEO title="Amreli To Mumbai - Hindustan Taxi Service" description="Book a taxi from Amreli to Mumbai with Hindustan Taxi Service at affordable prices." /> <AmreliToMumbai /> </>} />
        <Route path='/amreli-to-ambardisafaripark' element={<> <SEO title="Amreli To Ambardi Safari Park - Hindustan Taxi Service" description="Book a taxi from Amreli to Ambardi Safari Park with Hindustan Taxi Service at affordable prices." /> <AmreliToAmbardiSafariPark /> </>} />
        <Route path='/amreli-to-girnationalpark' element={<> <SEO title="Amreli To Gir National Park - Hindustan Taxi Service" description="Book a taxi from Amreli to Gir National Park with Hindustan Taxi Service at affordable prices." /> <AmreliToGirNationalPark /> </>} />
        <Route path='/amreli-to-sasangir' element={<> <SEO title="Amreli To Sasan Gir - Hindustan Taxi Service" description="Book a taxi from Amreli to Sasan Gir with Hindustan Taxi Service at affordable prices." /> <AmreliToSasanGir /> </>} />



        {/* silvassa */}
        <Route
          path='/silvassa-to-mumbai'
          element={(
            <>
              <SEO
                title="Silvassa To Mumbai - Hindustan Taxi Service"
                description="Book a taxi from Silvassa to Mumbai with Hindustan Taxi Service. We provide one-way, round-trip, and hourly rental services."
                keywords="taxi service, Silvassa to Mumbai, car rental, travel booking"
              />
              <SilvassaToMumbai />
            </>
          )}
        />
        <Route
          path='/silvassa-to-goa'
          element={(
            <>
              <SEO
                title="Silvassa To Goa - Hindustan Taxi Service"
                description="Travel from Silvassa to Goa with our reliable taxi service. We offer one-way, round-trip, and hourly rental services."
                keywords="taxi service, Silvassa to Goa, car rental, travel booking"
              />
              <SilvassaToGoa />
            </>
          )}
        />
        <Route
          path='/silvassa-to-ahmedabad'
          element={(
            <>
              <SEO
                title="Silvassa To Ahmedabad - Hindustan Taxi Service"
                description="Get comfortable taxi rides from Silvassa to Ahmedabad with Hindustan Taxi Service. One-way and round-trip services available."
                keywords="taxi service, Silvassa to Ahmedabad, car rental, travel booking"
              />
              <SilvassaToAhmedabad />
            </>
          )}
        />
        <Route
          path='/silvassa-to-lonavala'
          element={(
            <>
              <SEO
                title="Silvassa To Lonavala - Hindustan Taxi Service"
                description="Explore Lonavala by booking a taxi from Silvassa. We offer one-way, round-trip, and hourly rental services."
                keywords="taxi service, Silvassa to Lonavala, car rental, travel booking"
              />
              <SilvassaToLonavala />
            </>
          )}
        />
        <Route
          path='/silvassa-to-nashik'
          element={(
            <>
              <SEO
                title="Silvassa To Nashik - Hindustan Taxi Service"
                description="Travel from Silvassa to Nashik conveniently with our taxi services. One-way and round-trip options available."
                keywords="taxi service, Silvassa to Nashik, car rental, travel booking"
              />
              <SilvassaToNashik />
            </>
          )}
        />
        <Route
          path='/silvassa-to-pune'
          element={(
            <>
              <SEO
                title="Silvassa To Pune - Hindustan Taxi Service"
                description="Book a taxi from Silvassa to Pune for a comfortable journey. We provide one-way and round-trip services."
                keywords="taxi service, Silvassa to Pune, car rental, travel booking"
              />
              <SilvassaToPune />
            </>
          )}
        />
        <Route
          path='/silvassa-to-kalyan'
          element={(
            <>
              <SEO
                title="Silvassa To Kalyan - Hindustan Taxi Service"
                description="Get reliable taxi services from Silvassa to Kalyan with Hindustan Taxi Service. One-way and round-trip options available."
                keywords="taxi service, Silvassa to Kalyan, car rental, travel booking"
              />
              <SilvassaToKalyan />
            </>
          )}
        />
        <Route
          path='/silvassa-to-thane'
          element={(
            <>
              <SEO
                title="Silvassa To Thane - Hindustan Taxi Service"
                description="Travel from Silvassa to Thane with comfort and ease by booking a taxi. We offer one-way and round-trip services."
                keywords="taxi service, Silvassa to Thane, car rental, travel booking"
              />
              <SilvassaToThane />
            </>
          )}
        />
        <Route
          path='/silvassa-to-rajkot'
          element={(
            <>
              <SEO
                title="Silvassa To Rajkot - Hindustan Taxi Service"
                description="Book a taxi from Silvassa to Rajkot with competitive pricing. One-way and round-trip services available."
                keywords="taxi service, Silvassa to Rajkot, car rental, travel booking"
              />
              <SilvassaToRajkot />
            </>
          )}
        />
        <Route
          path='/silvassa-to-udaipur'
          element={(
            <>
              <SEO
                title="Silvassa To Udaipur - Hindustan Taxi Service"
                description="Explore the beauty of Udaipur by booking a taxi from Silvassa. We provide one-way and round-trip services."
                keywords="taxi service, Silvassa to Udaipur, car rental, travel booking"
              />
              <SilvassaToUdaipur />
            </>
          )}
        />
        <Route
          path='/silvassa-to-boisar'
          element={(
            <>
              <SEO
                title="Silvassa To Boisar - Hindustan Taxi Service"
                description="Travel conveniently from Silvassa to Boisar with our taxi services. One-way and round-trip options available."
                keywords="taxi service, Silvassa to Boisar, car rental, travel booking"
              />
              <SilvassaToBoisar />
            </>
          )}
        />
        <Route
          path='/silvassa-to-VasaiVirar'
          element={(
            <>
              <SEO
                title="Silvassa To Vasai Virar - Hindustan Taxi Service"
                description="Book a comfortable taxi from Silvassa to Vasai Virar easily. We provide one-way and round-trip services."
                keywords="taxi service, Silvassa to Vasai Virar, car rental, travel booking"
              />
              <SilvassaToVasaiVirar />
            </>
          )}
        />
        <Route
          path='/silvassa-to-surat'
          element={(
            <>
              <SEO
                title="Silvassa To Surat - Hindustan Taxi Service"
                description="Get taxi services from Silvassa to Surat at affordable prices. We offer one-way, round-trip, and hourly rental services."
                keywords="taxi service, Silvassa to Surat, car rental, travel booking"
              />
              <SilvassaToSurat />
            </>
          )}
        />
        <Route
          path='/silvassa-to-vadodara'
          element={(
            <>
              <SEO
                title="Silvassa To Vadodara - Hindustan Taxi Service"
                description="Travel from Silvassa to Vadodara comfortably with our taxi services. One-way and round-trip options available."
                keywords="taxi service, Silvassa to Vadodara, car rental, travel booking"
              />
              <SilvassaToVadodara />
            </>
          )}
        />





        {/* bardoli */}

        <Route
          path='/bardoli-to-mumbai'
          element={(
            <>
              <SEO
                title="Bardoli To Mumbai - Hindustan Taxi Service"
                description="Book a taxi from Bardoli to Mumbai with Hindustan Taxi Service. We provide one-way, round-trip, and hourly rental services."
                keywords="taxi service, Bardoli to Mumbai, car rental, travel booking"
              />
              <BardoliToMumbai />
            </>
          )}
        />
        <Route
          path='/bardoli-to-kalyan'
          element={(
            <>
              <SEO
                title="Bardoli To Kalyan - Hindustan Taxi Service"
                description="Travel conveniently from Bardoli to Kalyan with our reliable taxi service. One-way and round-trip options available."
                keywords="taxi service, Bardoli to Kalyan, car rental, travel booking"
              />
              <BardoliToKalyan />
            </>
          )}
        />
        <Route
          path='/bardoli-to-thane'
          element={(
            <>
              <SEO
                title="Bardoli To Thane - Hindustan Taxi Service"
                description="Get comfortable taxi rides from Bardoli to Thane with Hindustan Taxi Service. One-way and round-trip services available."
                keywords="taxi service, Bardoli to Thane, car rental, travel booking"
              />
              <BardoliToThane />
            </>
          )}
        />
        <Route
          path='/bardoli-to-bhiwandi'
          element={(
            <>
              <SEO
                title="Bardoli To Bhiwandi - Hindustan Taxi Service"
                description="Explore Bhiwandi by booking a taxi from Bardoli. We offer one-way, round-trip, and hourly rental services."
                keywords="taxi service, Bardoli to Bhiwandi, car rental, travel booking"
              />
              <BardoliToBhiwandi />
            </>
          )}
        />
        <Route
          path='/bardoli-to-badlapur'
          element={(
            <>
              <SEO
                title="Bardoli To Badlapur - Hindustan Taxi Service"
                description="Travel from Bardoli to Badlapur with our reliable taxi services. One-way and round-trip options available."
                keywords="taxi service, Bardoli to Badlapur, car rental, travel booking"
              />
              <BardoliToBadlapur />
            </>
          )}
        />
        <Route
          path='/bardoli-to-dadar'
          element={(
            <>
              <SEO
                title="Bardoli To Dadar - Hindustan Taxi Service"
                description="Get reliable taxi services from Bardoli to Dadar with Hindustan Taxi Service. One-way and round-trip options available."
                keywords="taxi service, Bardoli to Dadar, car rental, travel booking"
              />
              <BardoliToDadar />
            </>
          )}
        />
        <Route
          path='/bardoli-to-panvel'
          element={(
            <>
              <SEO
                title="Bardoli To Panvel - Hindustan Taxi Service"
                description="Book a taxi from Bardoli to Panvel for a comfortable journey. We provide one-way and round-trip services."
                keywords="taxi service, Bardoli to Panvel, car rental, travel booking"
              />
              <BardoliToPanvel />
            </>
          )}
        />
        <Route
          path='/bardoli-to-mumbaicentral'
          element={(
            <>
              <SEO
                title="Bardoli To Mumbai Central - Hindustan Taxi Service"
                description="Travel conveniently from Bardoli to Mumbai Central with our taxi services. One-way and round-trip options available."
                keywords="taxi service, Bardoli to Mumbai Central, car rental, travel booking"
              />
              <BardoliToMumbaiCentral />
            </>
          )}
        />
        <Route
          path='/bardoli-to-vasaivirar'
          element={(
            <>
              <SEO
                title="Bardoli To Vasai Virar - Hindustan Taxi Service"
                description="Get taxi services from Bardoli to Vasai Virar at affordable prices. One-way and round-trip services available."
                keywords="taxi service, Bardoli to Vasai Virar, car rental, travel booking"
              />
              <BardoliToVasaiVirar />
            </>
          )}
        />
        <Route
          path='/bardoli-to-mumbaiairport'
          element={(
            <>
              <SEO
                title="Bardoli To Mumbai Airport - Hindustan Taxi Service"
                description="Travel from Bardoli to Mumbai Airport with our reliable taxi services. One-way and round-trip options available."
                keywords="taxi service, Bardoli to Mumbai Airport, car rental, travel booking"
              />
              <BardoliToMumbaiAirport />
            </>
          )}
        />
        <Route
          path='/bardoli-to-navimumbai'
          element={(
            <>
              <SEO
                title="Bardoli To Navi Mumbai - Hindustan Taxi Service"
                description="Book a taxi from Bardoli to Navi Mumbai for a comfortable journey. We provide one-way and round-trip services."
                keywords="taxi service, Bardoli to Navi Mumbai, car rental, travel booking"
              />
              <BardoliToNaviMumbai />
            </>
          )}
        />
        <Route
          path='/bardoli-to-pune'
          element={(
            <>
              <SEO
                title="Bardoli To Pune - Hindustan Taxi Service"
                description="Travel conveniently from Bardoli to Pune with our taxi services. One-way and round-trip options available."
                keywords="taxi service, Bardoli to Pune, car rental, travel booking"
              />
              <BardoliToPune />
            </>
          )}
        />
        <Route
          path='/bardoli-to-ahmedabadairport'
          element={(
            <>
              <SEO
                title="Bardoli To Ahmedabad Airport - Hindustan Taxi Service"
                description="Get taxi services from Bardoli to Ahmedabad Airport at affordable prices. One-way and round-trip services available."
                keywords="taxi service, Bardoli to Ahmedabad Airport, car rental, travel booking"
              />
              <BardoliToAhmedabadAirport />
            </>
          )}
        />



        <Route
          path='/bardoli-to-suratairport'
          element={(
            <>
              <SEO
                title="Bardoli To Surat Airport - Hindustan Taxi Service"
                description="Book a taxi from Bardoli to Surat Airport with Hindustan Taxi Service. We provide one-way, round-trip, and hourly rental services."
                keywords="taxi service, Bardoli to Surat Airport, car rental, travel booking"
              />
              <BardoliToSuratAirport />
            </>
          )}
        />
        <Route
          path='/bardoli-to-nashik'
          element={(
            <>
              <SEO
                title="Bardoli To Nashik - Hindustan Taxi Service"
                description="Travel conveniently from Bardoli to Nashik with our reliable taxi service. One-way and round-trip options available."
                keywords="taxi service, Bardoli to Nashik, car rental, travel booking"
              />
              <BardoliToNashik />
            </>
          )}
        />
        <Route
          path='/bardoli-to-shirdi'
          element={(
            <>
              <SEO
                title="Bardoli To Shirdi - Hindustan Taxi Service"
                description="Get comfortable taxi rides from Bardoli to Shirdi with Hindustan Taxi Service. One-way and round-trip services available."
                keywords="taxi service, Bardoli to Shirdi, car rental, travel booking"
              />
              <BardoliToShirdi />
            </>
          )}
        />
        <Route
          path='/bardoli-to-rajkot'
          element={(
            <>
              <SEO
                title="Bardoli To Rajkot - Hindustan Taxi Service"
                description="Explore Rajkot by booking a taxi from Bardoli. We offer one-way, round-trip, and hourly rental services."
                keywords="taxi service, Bardoli to Rajkot, car rental, travel booking"
              />
              <BardoliToRajkot />
            </>
          )}
        />
        <Route
          path='/bardoli-to-indore'
          element={(
            <>
              <SEO
                title="Bardoli To Indore - Hindustan Taxi Service"
                description="Travel from Bardoli to Indore with our reliable taxi services. One-way and round-trip options available."
                keywords="taxi service, Bardoli to Indore, car rental, travel booking"
              />
              <BardoliToIndore />
            </>
          )}
        />
        <Route
          path='/bardoli-to-udaipur'
          element={(
            <>
              <SEO
                title="Bardoli To Udaipur - Hindustan Taxi Service"
                description="Get taxi services from Bardoli to Udaipur at affordable prices. One-way and round-trip services available."
                keywords="taxi service, Bardoli to Udaipur, car rental, travel booking"
              />
              <BardoliToUdaipur />
            </>
          )}
        />
        <Route
          path='/bardoli-to-daman'
          element={(
            <>
              <SEO
                title="Bardoli To Daman - Hindustan Taxi Service"
                description="Travel conveniently from Bardoli to Daman with our taxi services. One-way and round-trip options available."
                keywords="taxi service, Bardoli to Daman, car rental, travel booking"
              />
              <BardoliToDaman />
            </>
          )}
        />
        <Route
          path='/bardoli-to-bharuch'
          element={(
            <>
              <SEO
                title="Bardoli To Bharuch - Hindustan Taxi Service"
                description="Book a taxi from Bardoli to Bharuch for a comfortable journey. We provide one-way and round-trip services."
                keywords="taxi service, Bardoli to Bharuch, car rental, travel booking"
              />
              <BardoliToBharuch />
            </>
          )}
        />
        <Route
          path='/bardoli-to-anand'
          element={(
            <>
              <SEO
                title="Bardoli To Anand - Hindustan Taxi Service"
                description="Get reliable taxi services from Bardoli to Anand with Hindustan Taxi Service. One-way and round-trip options available."
                keywords="taxi service, Bardoli to Anand, car rental, travel booking"
              />
              <BardoliToAnand />
            </>
          )}
        />
        <Route
          path='/bardoli-to-bhuj'
          element={(
            <>
              <SEO
                title="Bardoli To Bhuj - Hindustan Taxi Service"
                description="Travel from Bardoli to Bhuj with our reliable taxi services. One-way and round-trip options available."
                keywords="taxi service, Bardoli to Bhuj, car rental, travel booking"
              />
              <BardoliToBhuj />
            </>
          )}
        />
        <Route
          path='/bardoli-to-goa'
          element={(
            <>
              <SEO
                title="Bardoli To Goa - Hindustan Taxi Service"
                description="Book a taxi from Bardoli to Goa for a comfortable journey. We provide one-way and round-trip services."
                keywords="taxi service, Bardoli to Goa, car rental, travel booking"
              />
              <BardoliToGoa />
            </>
          )}
        />
        <Route
          path='/bardoli-to-jamnagar'
          element={(
            <>
              <SEO
                title="Bardoli To Jamnagar - Hindustan Taxi Service"
                description="Travel conveniently from Bardoli to Jamnagar with our taxi services. One-way and round-trip options available."
                keywords="taxi service, Bardoli to Jamnagar, car rental, travel booking"
              />
              <BardoliToJamnagar />
            </>
          )}
        />
        <Route
          path='/bardoli-to-navsari'
          element={(
            <>
              <SEO
                title="Bardoli To Navsari - Hindustan Taxi Service"
                description="Get taxi services from Bardoli to Navsari at affordable prices. One-way and round-trip services available."
                keywords="taxi service, Bardoli to Navsari, car rental, travel booking"
              />
              <BardoliToNavsari />
            </>
          )}
        />
        <Route
          path='/bardoli-to-silvassa'
          element={(
            <>
              <SEO
                title="Bardoli To Silvassa - Hindustan Taxi Service"
                description="Travel conveniently from Bardoli to Silvassa with our reliable taxi services. One-way and round-trip options available."
                keywords="taxi service, Bardoli to Silvassa, car rental, travel booking"
              />
              <BardoliToSilvassa />
            </>
          )}
        />
        <Route
          path='/bardoli-to-vadodara'
          element={(
            <>
              <SEO
                title="Bardoli To Vadodara - Hindustan Taxi Service"
                description="Book a taxi from Bardoli to Vadodara for a comfortable journey. We provide one-way and round-trip services."
                keywords="taxi service, Bardoli to Vadodara, car rental, travel booking"
              />
              <BardoliToVadodara />
            </>
          )}
        />
        <Route
          path='/bardoli-to-vapi'
          element={(
            <>
              <SEO
                title="Bardoli To Vapi - Hindustan Taxi Service"
                description="Travel from Bardoli to Vapi with our reliable taxi services. One-way and round-trip options available."
                keywords="taxi service, Bardoli to Vapi, car rental, travel booking"
              />
              <BardoliToVapi />
            </>
          )}
        />




        {/* bharuch */}
        {/* {/ bharuch  /} */}
        <Route
          path='/bharuch-to-surat'
          element={(
            <>
              <SEO
                title="Bharuch To Surat - Hindustan Taxi Service"
                description="Book a taxi from Bharuch to Surat with Hindustan Taxi Service. We offer one-way, round-trip, and hourly rental services."
                keywords="taxi service, Bharuch to Surat, car rental, travel booking"
              />
              <BharuchToSurat />
            </>
          )}
        />
        <Route
          path='/bharuch-to-ahmairport'
          element={(
            <>
              <SEO
                title="Bharuch To Ahmedabad Airport - Hindustan Taxi Service"
                description="Travel conveniently from Bharuch to Ahmedabad Airport with our reliable taxi service. One-way and round-trip options available."
                keywords="taxi service, Bharuch to Ahmedabad Airport, car rental, travel booking"
              />
              <BharuchToAhmedabadAirport />
            </>
          )}
        />
        <Route
          path='/bharuch-to-mumbaiairport'
          element={(
            <>
              <SEO
                title="Bharuch To Mumbai Airport - Hindustan Taxi Service"
                description="Get comfortable taxi rides from Bharuch to Mumbai Airport with Hindustan Taxi Service. One-way and round-trip services available."
                keywords="taxi service, Bharuch to Mumbai Airport, car rental, travel booking"
              />
              <BharuchToMumbaiAirport />
            </>
          )}
        />
        <Route
          path='/bharuch-to-pune'
          element={(
            <>
              <SEO
                title="Bharuch To Pune - Hindustan Taxi Service"
                description="Explore Pune by booking a taxi from Bharuch. We offer one-way, round-trip, and hourly rental services."
                keywords="taxi service, Bharuch to Pune, car rental, travel booking"
              />
              <BharuchToPune />
            </>
          )}
        />
        <Route
          path='/bharuch-to-daman'
          element={(
            <>
              <SEO
                title="Bharuch To Daman - Hindustan Taxi Service"
                description="Travel conveniently from Bharuch to Daman with our taxi services. One-way and round-trip options available."
                keywords="taxi service, Bharuch to Daman, car rental, travel booking"
              />
              <BharuchToDaman />
            </>
          )}
        />
        <Route
          path='/bharuch-to-vadodara'
          element={(
            <>
              <SEO
                title="Bharuch To Vadodara - Hindustan Taxi Service"
                description="Get taxi services from Bharuch to Vadodara for a comfortable journey. We provide one-way and round-trip services."
                keywords="taxi service, Bharuch to Vadodara, car rental, travel booking"
              />
              <BharuchToVadodara />
            </>
          )}
        />
        <Route
          path='/bharuch-to-udaipur'
          element={(
            <>
              <SEO
                title="Bharuch To Udaipur - Hindustan Taxi Service"
                description="Travel from Bharuch to Udaipur with our reliable taxi services. One-way and round-trip options available."
                keywords="taxi service, Bharuch to Udaipur, car rental, travel booking"
              />
              <BharuchToUdaipur />
            </>
          )}
        />
        <Route
          path='/bharuch-to-vapi'
          element={(
            <>
              <SEO
                title="Bharuch To Vapi - Hindustan Taxi Service"
                description="Book a taxi from Bharuch to Vapi for a comfortable journey. We provide one-way and round-trip services."
                keywords="taxi service, Bharuch to Vapi, car rental, travel booking"
              />
              <BharuchToVapi />
            </>
          )}
        />
        <Route
          path='/bharuch-to-silvassa'
          element={(
            <>
              <SEO
                title="Bharuch To Silvassa - Hindustan Taxi Service"
                description="Travel conveniently from Bharuch to Silvassa with our reliable taxi services. One-way and round-trip options available."
                keywords="taxi service, Bharuch to Silvassa, car rental, travel booking"
              />
              <BharuchToSilvassa />
            </>
          )}
        />
        <Route
          path='/bharuch-to-kalyan'
          element={(
            <>
              <SEO
                title="Bharuch To Kalyan - Hindustan Taxi Service"
                description="Get reliable taxi services from Bharuch to Kalyan with Hindustan Taxi Service. One-way and round-trip options available."
                keywords="taxi service, Bharuch to Kalyan, car rental, travel booking"
              />
              <BharuchToKalyan />
            </>
          )}
        />
        <Route
          path='/bharuch-to-bhiwandi'
          element={(
            <>
              <SEO
                title="Bharuch To Bhiwandi - Hindustan Taxi Service"
                description="Travel from Bharuch to Bhiwandi with our reliable taxi services. One-way and round-trip options available."
                keywords="taxi service, Bharuch to Bhiwandi, car rental, travel booking"
              />
              <BharuchToBhiwandi />
            </>
          )}
        />
        <Route
          path='/bharuch-to-navimumbai'
          element={(
            <>
              <SEO
                title="Bharuch To Navi Mumbai - Hindustan Taxi Service"
                description="Travel conveniently from Bharuch to Navi Mumbai with our taxi services. One-way and round-trip options available."
                keywords="taxi service, Bharuch to Navi Mumbai, car rental, travel booking"
              />
              <BharuchToNaviMumbai />
            </>
          )}
        />
        <Route
          path='/bharuch-to-badlapur'
          element={(
            <>
              <SEO
                title="Bharuch To Badlapur - Hindustan Taxi Service"
                description="Get taxi services from Bharuch to Badlapur at affordable prices. One-way and round-trip services available."
                keywords="taxi service, Bharuch to Badlapur, car rental, travel booking"
              />
              <BharuchToBadlapur />
            </>
          )}
        />
        <Route
          path='/bharuch-to-ajmer'
          element={(
            <>
              <SEO
                title="Bharuch To Ajmer - Hindustan Taxi Service"
                description="Travel from Bharuch to Ajmer with our reliable taxi services. One-way and round-trip options available."
                keywords="taxi service, Bharuch to Ajmer, car rental, travel booking"
              />
              <BharuchToAjmer />
            </>
          )}
        />
        <Route
          path='/bharuch-to-goa'
          element={(
            <>
              <SEO
                title="Bharuch To Goa - Hindustan Taxi Service"
                description="Book a taxi from Bharuch to Goa for a comfortable journey. We provide one-way and round-trip services."
                keywords="taxi service, Bharuch to Goa, car rental, travel booking"
              />
              <BharuchToGoa />
            </>
          )}
        />
        <Route
          path='/bharuch-to-rajkot'
          element={(
            <>
              <SEO
                title="Bharuch To Rajkot - Hindustan Taxi Service"
                description="Explore Rajkot by booking a taxi from Bharuch. We offer one-way, round-trip, and hourly rental services."
                keywords="taxi service, Bharuch to Rajkot, car rental, travel booking"
              />
              <BharuchToRajkot />
            </>
          )}
        />
        <Route
          path='/bharuch-to-indore'
          element={(
            <>
              <SEO
                title="Bharuch To Indore - Hindustan Taxi Service"
                description="Travel from Bharuch to Indore with our reliable taxi services. One-way and round-trip options available."
                keywords="taxi service, Bharuch to Indore, car rental, travel booking"
              />
              <BharuchToIndore />
            </>
          )}
        />



        {/* // bharuch */}

        <Route
          path='/bharuch-to-surat'
          element={
            <>
              <SEO title="Bharuch To Surat - Hindustan Taxi Service" description="Book a taxi from Bharuch to Surat with Hindustan Taxi Service at affordable prices." keywords="taxi service, Bharuch to Surat, affordable taxi" />
              <BharuchToSurat />
            </>
          }
        />
        <Route
          path='/bharuch-to-ahmairport'
          element={
            <>
              <SEO title="Bharuch To Ahmedabad Airport - Hindustan Taxi Service" description="Book a taxi from Bharuch to Ahmedabad Airport with Hindustan Taxi Service." keywords="taxi service, Bharuch to Ahmedabad Airport, airport taxi" />
              <BharuchToAhmedabadAirport />
            </>
          }
        />
        <Route
          path='/bharuch-to-mumbaiairport'
          element={
            <>
              <SEO title="Bharuch To Mumbai Airport - Hindustan Taxi Service" description="Book a taxi from Bharuch to Mumbai Airport with Hindustan Taxi Service." keywords="taxi service, Bharuch to Mumbai Airport, airport taxi" />
              <BharuchToMumbaiAirport />
            </>
          }
        />
        <Route
          path='/bharuch-to-pune'
          element={
            <>
              <SEO title="Bharuch To Pune - Hindustan Taxi Service" description="Book a taxi from Bharuch to Pune with Hindustan Taxi Service at great prices." keywords="taxi service, Bharuch to Pune, affordable taxi" />
              <BharuchToPune />
            </>
          }
        />
        <Route
          path='/bharuch-to-daman'
          element={
            <>
              <SEO title="Bharuch To Daman - Hindustan Taxi Service" description="Book a taxi from Bharuch to Daman with Hindustan Taxi Service." keywords="taxi service, Bharuch to Daman, travel taxi" />
              <BharuchToDaman />
            </>
          }
        />
        <Route
          path='/bharuch-to-vadodara'
          element={
            <>
              <SEO title="Bharuch To Vadodara - Hindustan Taxi Service" description="Book a taxi from Bharuch to Vadodara with Hindustan Taxi Service." keywords="taxi service, Bharuch to Vadodara, travel taxi" />
              <BharuchToVadodara />
            </>
          }
        />
        <Route
          path='/bharuch-to-udaipur'
          element={
            <>
              <SEO title="Bharuch To Udaipur - Hindustan Taxi Service" description="Book a taxi from Bharuch to Udaipur with Hindustan Taxi Service." keywords="taxi service, Bharuch to Udaipur, travel taxi" />
              <BharuchToUdaipur />
            </>
          }
        />
        <Route
          path='/bharuch-to-vapi'
          element={
            <>
              <SEO title="Bharuch To Vapi - Hindustan Taxi Service" description="Book a taxi from Bharuch to Vapi with Hindustan Taxi Service." keywords="taxi service, Bharuch to Vapi, travel taxi" />
              <BharuchToVapi />
            </>
          }
        />
        <Route
          path='/bharuch-to-silvassa'
          element={
            <>
              <SEO title="Bharuch To Silvassa - Hindustan Taxi Service" description="Book a taxi from Bharuch to Silvassa with Hindustan Taxi Service." keywords="taxi service, Bharuch to Silvassa, travel taxi" />
              <BharuchToSilvassa />
            </>
          }
        />
        <Route
          path='/bharuch-to-kalyan'
          element={
            <>
              <SEO title="Bharuch To Kalyan - Hindustan Taxi Service" description="Book a taxi from Bharuch to Kalyan with Hindustan Taxi Service." keywords="taxi service, Bharuch to Kalyan, travel taxi" />
              <BharuchToKalyan />
            </>
          }
        />
        <Route
          path='/bharuch-to-bhiwandi'
          element={
            <>
              <SEO title="Bharuch To Bhiwandi - Hindustan Taxi Service" description="Book a taxi from Bharuch to Bhiwandi with Hindustan Taxi Service." keywords="taxi service, Bharuch to Bhiwandi, travel taxi" />
              <BharuchToBhiwandi />
            </>
          }
        />
        <Route
          path='/bharuch-to-navimumbai'
          element={
            <>
              <SEO title="Bharuch To Navi Mumbai - Hindustan Taxi Service" description="Book a taxi from Bharuch to Navi Mumbai with Hindustan Taxi Service." keywords="taxi service, Bharuch to Navi Mumbai, travel taxi" />
              <BharuchToNaviMumbai />
            </>
          }
        />
        <Route
          path='/bharuch-to-badlapur'
          element={
            <>
              <SEO title="Bharuch To Badlapur - Hindustan Taxi Service" description="Book a taxi from Bharuch to Badlapur with Hindustan Taxi Service." keywords="taxi service, Bharuch to Badlapur, travel taxi" />
              <BharuchToBadlapur />
            </>
          }
        />
        <Route
          path='/bharuch-to-ajmer'
          element={
            <>
              <SEO title="Bharuch To Ajmer - Hindustan Taxi Service" description="Book a taxi from Bharuch to Ajmer with Hindustan Taxi Service." keywords="taxi service, Bharuch to Ajmer, travel taxi" />
              <BharuchToAjmer />
            </>
          }
        />
        <Route
          path='/bharuch-to-goa'
          element={
            <>
              <SEO title="Bharuch To Goa - Hindustan Taxi Service" description="Book a taxi from Bharuch to Goa with Hindustan Taxi Service." keywords="taxi service, Bharuch to Goa, travel taxi" />
              <BharuchToGoa />
            </>
          }
        />
        <Route
          path='/bharuch-to-rajkot'
          element={
            <>
              <SEO title="Bharuch To Rajkot - Hindustan Taxi Service" description="Book a taxi from Bharuch to Rajkot with Hindustan Taxi Service." keywords="taxi service, Bharuch to Rajkot, travel taxi" />
              <BharuchToRajkot />
            </>
          }
        />
        <Route
          path='/bharuch-to-indore'
          element={
            <>
              <SEO title="Bharuch To Indore - Hindustan Taxi Service" description="Book a taxi from Bharuch to Indore with Hindustan Taxi Service." keywords="taxi service, Bharuch to Indore, travel taxi" />
              <BharuchToIndore />
            </>
          }
        />



        <Route path='/' element={<>
          <SEO title="Home - Hindustan Taxi Service" description="Book a taxi from Home with Hindustan Taxi Service." keywords="taxi service, Home, travel taxi" /> <Home closeModal={closeModal} isModalVisible={isModalVisible} /> </>} />

        <Route path='/onewaydetails' element={<>
          <SEO title="One Way Details  - Hindustan Taxi Service" description="Book a taxi from One Way Details with Hindustan Taxi Service." keywords="taxi service, One Way Details, travel taxi" /><OneWayDetails /> </>} />
        <Route path='/roundtripdetails' element={<>
          <SEO title="Round Trip Details - Hindustan Taxi Service" description="Book a taxi from Round Trip Details  with Hindustan Taxi Service." keywords="taxi service, Round Trip Details, travel taxi" /> <RoundTripDetails /> </>} />
        <Route path='/eightHrsdetails' element={<>
          <SEO title="Eight Hours Details - Hindustan Taxi Service" description="Book a taxi from Eight Hours Details with Hindustan Taxi Service." keywords="taxi service, Eight Hours Details, travel taxi" /> <EightHoursDetails /> </>} />
        <Route path='/tenHrsdetails' element={<>
          <SEO title="Ten Hrs etails - Hindustan Taxi Service" description="Book a taxi from Ten Hrs etails with Hindustan Taxi Service." keywords="taxi service, Ten Hrs etails, travel taxi" /><TenHoursDetails /> </>} />

        <Route path='/twelveHrsdetails' element={<>
          <SEO title="Twelve Hrs etails - Hindustan Taxi Service" description="Book a taxi from Twelve Hrs Details with Hindustan Taxi Service." keywords="taxi service, Twelve Hrs etails, travel taxi" /><TwelveHoursDetails />
        </>} />
        <Route path='/airportHrsdetails' element={<>
          <SEO title="Airport Details - Hindustan Taxi Service" description="Book a taxi from Airport Details with Hindustan Taxi Service." keywords="taxi service, Airport Details, travel taxi" /><AirportDetails /> </>} />
        <Route path='/car-booking/:id' element={<>
          <SEO title="OneWay car Details - Hindustan Taxi Service" description="Book a taxi from OneWay car Details with Hindustan Taxi Service." keywords="taxi service, OneWay car Details, travel taxi" /><OneWay_car_details /> </>} />
        <Route path='/blog' element={<>
          <SEO title="Blog Details - Hindustan Taxi Service" description="Book a taxi from Blog Details with Hindustan Taxi Service." keywords="taxi service, Blog Details, travel taxi" /><Blog /></>} />
        <Route path='/testimonial' element={<>
          <SEO title="Testimonial Details - Hindustan Taxi Service" description="Book a taxi from Testimonial Details with Hindustan Taxi Service." keywords="taxi service, Testimonial Details, travel taxi" /> <Testimonial /> </>} />
        <Route path='/privacy-policy' element={<>
          <SEO title="Privacy Policy - Hindustan Taxi Service" description="Book a taxi from Privacy Policy with Hindustan Taxi Service." keywords="taxi service, Privacy Policy, travel taxi" /><PrivacyPolicy /> </>} />
        <Route path='/demo' element={<DemoInvoice />} />
        <Route path='/refund-policy' element={<>
          <SEO title="Refund Policy - Hindustan Taxi Service" description="Book a taxi from Refund Policy with Hindustan Taxi Service." keywords="taxi service, Refund Policy, travel taxi" /> <RefundPolicy /> </>} />
        <Route path='/shipping-policy' element={<>
          <SEO title="Shipping Policy - Hindustan Taxi Service" description="Book a taxi from Shipping Policy with Hindustan Taxi Service." keywords="taxi service, Shipping Policy, travel taxi" /><ShippingPolicy /> </>} />
        <Route path='/terms-condition' element={<>
          <SEO title="Terms Conditions - Hindustan Taxi Service" description="Book a taxi from Terms Conditions with Hindustan Taxi Service." keywords="taxi service, Terms Conditions, travel taxi" /><Terms_conditions /> </>} />
        <Route path='/contactus' element={<>
          <SEO title="Contact Us - Hindustan Taxi Service" description="Book a taxi from Contact Us with Hindustan Taxi Service." keywords="taxi service, Contact Us, travel taxi" /><ContactUs /> </>} />
        <Route path='/aboutus' element={<>
          <SEO title="About Us - Hindustan Taxi Service" description="Book a taxi from About Us with Hindustan Taxi Service." keywords="taxi service, About Us, travel taxi" /> <AboutUs /> </>} />
        <Route path='/blog/:id' element={<>
          <SEO title="Blog Details - Hindustan Taxi Service" description="Book a taxi from Blog Details with Hindustan Taxi Service." keywords="taxi service, Blog Details, travel taxi" /> <BlogDetails /> </>} />
        <Route path='/router' element={<>
          <SEO title="Route Page Details - Hindustan Taxi Service" description="Book a taxi from Route Page Details with Hindustan Taxi Service." keywords="taxi service, Route Page Details, travel taxi" /> <Route_page /> </>} />

          <Route path="/:carId/car-details" element={<>
            <SEO title="Route Page Details - Hindustan Taxi Service" description="Book a taxi from Route Page Details with Hindustan Taxi Service." keywords="taxi service, Route Page Details, travel taxi" /> <HomeCarDetails /> </>} />


        <Route path='/payment-success' element={<Success />} />
        <Route path='/payment-failure' element={<Failed />} />
      </Routes>
      </div>
      {!noHeaderFooterPaths.includes(location.pathname) && <Footer />}
      </> 
  );
};


export default App