import React, { useState, useEffect } from "react";
import BookingForm from '../pages/BookingForm';
import TripImage from "../pages/TripImage";
import OurServices from "../pages/OurServices";
import DescriptionPage from "../pages/DescriptionPage";
import Testimonial from "./testimonial";
import Memories from "./Memories";
import { Toaster, toast } from 'react-hot-toast'; // Importing Toaster and toast from react-hot-toast
import Logo from '../../Images/logo.png';
import { FaPhone, FaUser } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import CarCardDetail from "../layouts/Car_details";


export default function Home() {
  // State for login form fields
  const [formData, setFormData] = useState({
    name: '',
    phoneNo: '',
    email: '',
  });

  // State to control modal visibility
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Check localStorage for login status on component mount
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    // If not logged in, show the modal
    setIsModalVisible(!isLoggedIn);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLogin = async () => {
    try {
      const response = await fetch('https://bhadegadiapi.taxiwalataxi.in/api/v1/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Send the form data as JSON
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Assuming you receive a success response
      if (data.status === 'success') {
        const localData = { "n": data.data.name, "e": data.data.email, "p": data.data.phoneNo }
        localStorage.setItem('isLoggedIn', JSON.stringify(localData)); // Set login status
        setIsModalVisible(false); // Close the modal after logging in
        toast.success('Login successful!'); // Show success message
        window.location.reload(); 
      } else {
        toast.error(`${data.message}`); // Display validation error
      }
    } catch (error) {
      console.error('Error during login:', error);
      toast.error('An error occurred during login. Please try again.'); // Show error message
    }
  };

  return (
    <>
    <div className=" ">
    <BookingForm />
      <CarCardDetail />
      <TripImage />
      <OurServices />
      <DescriptionPage />
      <Testimonial />
      <Memories />
      </div>
      {isModalVisible && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ease-in-out">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm md:max-w-md lg:max-w-md mx-4 md:mx-6">
      {/* Logo at the top of the modal */}
      <div className="flex justify-center mb-4">
        <img src={Logo} alt="Logo" className="h-12 md:h-16" srcset={Logo} sizes="" loading="lazy" />
      </div>

      {/* Modal Title */}
      <h2 className="text-sm sm:text-lg font-semibold py-2 text-center text-gray-800">
        Login To Hindustan Taxi Service
      </h2>

      {/* Input fields for login */}
      <div className="space-y-4 mt-4">
        <div className="relative">
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            className="border border-gray-300 rounded-lg p-2 w-full pl-10 focus:outline-none focus:border-blue-500"
          />
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <FaUser fill="#414141" />
          </span>
        </div>

        <div className="relative">
          <input
            type="text"
            name="phoneNo"
            placeholder="Phone Number"
            value={formData.phoneNo}
            onChange={handleChange}
            maxLength="10"
            className="border border-gray-300 rounded-lg p-2 w-full pl-10 focus:outline-none focus:border-blue-500"
          />
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <FaPhone fill="#414141" />
          </span>
        </div>

        <div className="relative">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="border border-gray-300 rounded-lg p-2 w-full pl-10 focus:outline-none focus:border-blue-500"
          />
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <IoMdMail fill="#414141" size={21} />
          </span>
        </div>
      </div>

      {/* Login button */}
      <button
        onClick={handleLogin}
        className="bg-customBlue text-white rounded-lg p-2 w-full mt-4 hover:bg-black focus:outline-none"
      >
        Login
      </button>

      {/* Informational message */}
      <p className="text-[9px] sm:text-[15px] pt-3 text-center text-gray-600">
        Affordable Rates, 24/7 Support, Experienced Drivers.
        <br />
        Ready to ride? Let’s get started!!
      </p>
    </div>
  </div>
)}


      <Toaster position="top-center" reverseOrder={false} gutter={8} toastOptions={{ duration: 5000 }} />
    </>
  );
}