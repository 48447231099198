// import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { motion } from 'framer-motion'; // For page refresh animations
// import AOS from 'aos';
// import OneWayPage from '../pages/OneWayPage';
// import RoundTripPage from '../pages/RoundTripPage';
// import HourlyRent from '../pages/HourlyRent';
// import AirportPage from '../pages/form_to_AirportPage';

// const CarDetails = () => {
//   const location = useLocation();
//   const { item } = location.state || {}; // Retrieve the passed data
//   const [tripType, setTripType] = useState('ONE WAY');

//   useEffect(() => {
//     AOS.init({ duration: 1000 }); // Initialize AOS for scroll animations
//   }, []);

//   const handleTripTypeChange = (type) => {
//     setTripType(type);
//   };

//   return (
//     <div>
//       <div className="flex flex-col items-center justify-center p-4">
//         <motion.div
//           className="bg-white max-w-[1200px] w-full shadow-lg p-4 mt-5 rounded-[16px] relative z-10 border-[#E2E2E2] border-2"
//           initial={{ scale: 0.8, opacity: 0 }}
//           animate={{ scale: 1, opacity: 1 }}
//           transition={{ duration: 0.8 }} // Animation on load
//         >
//           <div className="flex justify-center mb-4">
//             <div className="grid grid-cols-2 gap-3 sm:grid-cols-4">
//               {['ONE WAY', 'ROUND TRIP', 'LOCAL', 'AIRPORT'].map((type) => (
//                 <motion.button
//                   key={type}
//                   className={`py-3 px-4 rounded-lg font-semibold text-[13px] transition-all duration-300 ease-in-out transform ${
//                     tripType === type
//                       ? 'bg-customBlue text-white shadow-lg'
//                       : 'bg-white text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-black hover:shadow-md'
//                   }`}
//                   onClick={() => handleTripTypeChange(type)}
//                   // Hover animation
//                   whileTap={{ scale: 0.95 }}  // Tap animation
//                 >
//                   {type}
//                 </motion.button>
//               ))}
//             </div>
//           </div>
//           <div className="w-full mt-4" data-aos="fade-up"> {/* AOS animation on scroll */}
//             {tripType === 'ONE WAY' && <OneWayPage />}
//             {tripType === 'ROUND TRIP' && <RoundTripPage />}
//             {tripType === 'LOCAL' && <HourlyRent />}
//             {tripType === 'AIRPORT' && <AirportPage />}
//           </div>
//         </motion.div>
//       </div>

//       <div className="container max-w-6xl mx-auto p-6 bg-white rounded-lg mt-6">
//         <h1 className="text-3xl font-semibold text-center text-customBlue mb-4">
//           {item.carcab_name} Car Rental
//         </h1>
//         <div className="flex space-x-4">
//           {/* Text Section */}
//           <div className="w-full md:w-1/2 text-gray-700 text-[15px] space-y-2">
//             <p><strong>Vehicle Name :</strong> {item.carcab_name}</p>
//             <b class='py-2'>Key Features : </b>
//             <p><strong>Seating Capacity:</strong> {item.pessenger}</p>
//             <p><strong>Extras :</strong> {item.extra}</p>
//             <p><strong>Luggage Space :</strong> {item.luggage}</p>
//             <p><strong>Rate :</strong> {item.bus_rate}</p>
//             <p><strong>Price :</strong> ₹20.00/Km</p>
//             <div>
//               <p>Comfort Features: </p>
//               <ul>
//                 <li>Air Conditioning</li>
//                 <li>Spacious Seats</li>
//                 <li>Music System</li>
//                 <li>Charging Points</li>

//               </ul>
//             </div>
//             <div>
//               <p>Rates : </p>
//               <ul>
//                 <li> ₹16 per Km for local trips </li>
//                 <li> ₹20 per Km for outstation trips</li>
//               </ul>
//             <p>Why Choose Force Urbania? </p>
//             <ul>
//               <li> Modern design and premium interiors for comfortable travel. </li>
//               <li> Ideal for family trips, group outings, and business tours. </li>
//               <li> High safety standards including seat belts, ABS, and tubeless tires. </li>
//              <li>Affordable Rates: Best pricing in the industry without hidden charges.</li>
//              <li> On-Time Service: Guaranteed punctuality for pickups and drop-offs. </li>
//              <li> 24/7 Support: Dedicated customer care for any assistance during your trip. </li>
//             <li>Well-Maintained Fleet: Regular servicing and checks to ensure safety. </li>
//               </ul>

//               <p> How to Book? </p>
//               <ul>
//                 <li>Visit the Hindustan Taxi Service website or mobile app.</li>
//                 <li>Select Force Urbania from the fleet.</li>
//                 <li>Enter your travel details and get an instant quote.</li>
//                 <li>Confirm your booking and enjoy your journey!</li>


//               </ul>
//             </div>
//           </div>

//           {/* Image Section */}
//           <div className="w-full md:w-1/2 flex justify-center mb-6">
//             <img
//               src={`https://hapi.hindustanrides.in/uploads/${item.carcabImg}`}
//               alt={item.carcab_name}
//               className="max-w-full h-auto rounded-lg" />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CarDetails;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import OneWayPage from "../pages/OneWayPage";
import RoundTripPage from "../pages/RoundTripPage";
import HourlyRent from "../pages/HourlyRent";
import AirportPage from "../pages/form_to_AirportPage";
import { FaCheckCircle } from "react-icons/fa";

const CarDetails = () => {
  const location = useLocation();
  const { item } = location.state || {}; // Retrieve the passed data
  const [tripType, setTripType] = useState("ONE WAY");

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleTripTypeChange = (type) => {
    setTripType(type);
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Hero Section */}
      <div className="hero-section bg-gradient-to-r from-customBlue to-indigo-600 p-8 text-center">
        <h1 className="text-white text-4xl font-bold mb-4">
          Book the Premium {item.carcab_name}
        </h1>
        <p className="text-white text-lg">
          Experience unmatched comfort and style for your journey!
        </p>
      </div>

      {/* Trip Type Tabs */}
      <div className="flex justify-center mt-6 ">
        <div className="grid grid-cols-2 gap-3 sm:grid-cols-4">
          {["ONE WAY", "ROUND TRIP", "LOCAL", "AIRPORT"].map((type) => (
            <motion.button
              key={type}
              className={`py-3 px-6 rounded-lg font-bold transition duration-300 ease-in-out transform ${
                tripType === type
                  ? "bg-customBlue text-white shadow-lg scale-105"
                  : "bg-gray-100 text-gray-600 border border-gray-300 hover:bg-gray-200"
              }`}
              onClick={() => handleTripTypeChange(type)}
              whileTap={{ scale: 0.95 }}
            >
              {type}
            </motion.button>
          ))}
        </div>
      </div>

      {/* Trip Type Content */}
      <div className="w-full mt-4 px-10" data-aos="fade-up">
        {tripType === "ONE WAY" && <OneWayPage />}
        {tripType === "ROUND TRIP" && <RoundTripPage />}
        {tripType === "LOCAL" && <HourlyRent />}
        {tripType === "AIRPORT" && <AirportPage />}
      </div>

      {/* Vehicle Details Section */}
      <div className="container max-w-6xl mx-auto p-6 rounded-lg mt-10">
       
        <div className="vehicle-details grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Details */}
          <div className="details-text text-gray-800 ">
          <h2 className="text-2xl font-semibold text-black mb-6">
          {item.carcab_name} Car Rental
        </h2>

            <div>
            <p className = "text-lg  text-black py-2 font-[500]"> Key Features : </p>
            <div className = "ml-5">
            <p>
              <span className="py-2 text-black"> - Seating Capacity :</span>{" "}
              {item.pessenger}
            </p>
            <p>
              <span className="py-2 text-black"> - Luggage Space :</span>{" "}
              {item.luggage}
            </p>
            <div className="features-list space-y-2 py-1">
              <p className="text-black font-[500]"> - Comfort Features :</p>
              <ul className="ml-5">
                <li className="flex items-center">
                <FaCheckCircle className="text-green-500 mr-2" />
                  Air Conditioning
                </li>
                <li className="flex items-center">
                <FaCheckCircle className="text-green-500 mr-2" />
                  Spacious Seats
                </li>
                <li className="flex items-center">
                <FaCheckCircle className="text-green-500 mr-2" />
                  Music System
                </li>
                <li className="flex items-center">
                <FaCheckCircle className="text-green-500 mr-2" />
                  Charging Points
                </li>
              </ul>
            </div>
            </div>
            </div>
            <p>
              <span className="text-lg  text-black py-2 font-[500]">Rate :</span><br/>
               <p className="ml-5"> - ₹16 per Km </p>
            </p>
         
          </div>

          {/* Image */}
          <div className="flex justify-center">
            <img
              src={`https://hapi.hindustanrides.in/uploads/${item.carcabImg}`}
              alt={item.carcab_name}
              className="rounded-lg shadow-lg  h-auto"
            />
          </div>
        </div>

        <div>
        <p className="text-lg font-[600] py-2">Why Choose {item.carcab_name}? </p>
        <ul>
          <li className="ml-5"> - Modern design and premium interiors for comfortable travel. </li>
          <li className="ml-5"> - Ideal for family trips, group outings, and business tours. </li>
          <li className="ml-5"> - High safety standards including seat belts, ABS, and tubeless tires. </li>
        </ul>
        </div>
        <div className= "py-5">
          <p className="text-lg font-[600] py-2">How to Book? </p>
          <ul>
            <li className="ml-5"> - Visit the Hindustan Taxi Service website or mobile app. </li>
            <li className="ml-5"> - Select Force Urbania from the fleet. </li>
            <li className="ml-5"> - Enter your travel details and get an instant quote. </li>
            <li className="ml-5"> - Confirm your booking and enjoy your journey! </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CarDetails;
