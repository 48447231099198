import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import EightHoursForm from './HourlyRentalForms/eight_hours_form';
import TenHoursForm from './HourlyRentalForms/ten_hours_form';
import TwelveHoursForm from './HourlyRentalForms/twelve_hours_form';


function LocalPage() {
  const [hourType, setHourType] = useState('eightHours');
  const [from, setFrom] = useState('');
  const [person, setPerson] = useState('');
  const [pickupDate, setPickupDate] = useState('');
  const [pickupTime, setPickupTime] = useState('');
  const [loadMap, setLoadMap] = useState(false);
  const [fromPlace, setFromPlace] = useState(null);
  const fromInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      initPlaceAPI();
    } else {
      loadGoogleMapScript(() => {
        setLoadMap(true);
        initPlaceAPI();
      });
    }
  }, []);

  // API key of the Google Map
  const GOOGLE_MAP_API_KEY = 'AIzaSyC34N-enppR8BKspnOpDkV6in2fGsz4drI';

  // Load Google Maps JavaScript API
  const loadGoogleMapScript = (callback) => {
    const googleMapScript = document.createElement('script');
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
    document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener('load', callback);
  };

  // Initialize the Google Places Autocomplete
  const initPlaceAPI = () => {
    const autocompleteFrom = new window.google.maps.places.Autocomplete(fromInputRef.current);
    autocompleteFrom.addListener('place_changed', () => {
      const place = autocompleteFrom.getPlace();
      setFrom(place.formatted_address);
      setFromPlace({
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        name: place.name
      });
    });
  };

  console.log(fromPlace);

  const today = new Date().toISOString().split('T')[0];

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!from || !person || !pickupDate || !pickupTime) {
      alert('Please fill in all required fields.');
      return;
    }

    // Create a data object based on the current hourType
    const formData = {
      hourType: hourType,
      from: from,
      person: person,
      pickupDate: pickupDate,
      pickupTime: pickupTime,
    };

    // Print form data to the console
    console.log('Form Data:', formData);

    // Construct query string
    const queryString = new URLSearchParams({
      type: 'onewayTrip',
      hourType: hourType,
      from: encodeURIComponent(from),
      person: encodeURIComponent(person),
      pickupDate: encodeURIComponent(pickupDate),
      pickupTime: encodeURIComponent(pickupTime),
    }).toString();

    // Navigate to the new route with the query string
    navigate(`/hourlydetails?${queryString}`);
  };

  const handleHoursChange = (type) => {
    setHourType(type);
  };

   return (
    <>
      <div className='flex justify-center items-center w-full'>
        <div className='text-center grid grid-cols-3 gap-0 border border-[#919191]'>
          <button className={` flex-1 py-2 px-5 ${hourType === 'eightHours' ? 'bg-customBlue text-customfontBlack text-white' : ' text-customfontBlack'} text-[13px] font-[600] border-r border-[#919191] `} onClick={() => handleHoursChange('eightHours')}> 8 hrs    80KM </button>
          <button className={` flex-1 py-2 px-5 ${hourType === 'tenHours' ? 'bg-customBlue text-customfontBlack text-white' : ' text-customfontBlack'}   text-[13px] font-[600] border-r border-[#919191] `} onClick={() => handleHoursChange('tenHours')}> 10 hrs  100KM </button>
          <button className={` flex-1 py-2 px-5 ${hourType === 'twelveHours' ? 'bg-customBlue text-customfontBlack text-white' : ' text-customfontBlack'}   text-[13px] font-[600] `} onClick={() => handleHoursChange('twelveHours')}> 12 hrs  120KM </button>
        </div>
      </div>
      {/* <div className="flex justify-center items-center w-full mt-8">
  <div className="flex gap-3 text-center w-full max-w-md">
    <button
      className={`flex-1 py-1 px-1 rounded-lg border  ${
        hourType === 'eightHours'
          ? 'bg-customBlue text-white border-customBlue shadow-md'
          : 'bg-white text-customfontBlack border-[#919191] hover:bg-gray-100 hover:text-black'
      } transition duration-300 ease-in-out transform hover:scale-105`}
      onClick={() => handleHoursChange('eightHours')}
    >
      8 hrs  &nbsp;  80KM
    </button>
    <button
      className={`flex-1 py-1 px-1 rounded-lg border ${
        hourType === 'tenHours'
          ? 'bg-customBlue text-white border-customBlue shadow-md'
          : 'bg-white text-customfontBlack border-[#919191] hover:bg-gray-100 hover:text-black'
      } transition duration-300 ease-in-out transform hover:scale-105`}
      onClick={() => handleHoursChange('tenHours')}
    >
      10 hrs  100KM
    </button>
    <button
      className={`flex-1 py-1 px-1 rounded-lg border ${
        hourType === 'twelveHours'
          ? 'bg-customBlue text-white border-customBlue shadow-md'
          : 'bg-white text-customfontBlack border-[#919191] hover:bg-gray-100 hover:text-black'
      } transition duration-300 ease-in-out transform hover:scale-105`}
      onClick={() => handleHoursChange('twelveHours')}
    >
      12 hrs  120KM
    </button>
  </div>
</div> */}



      {hourType === 'eightHours' && <EightHoursForm fromInputRef={fromInputRef} />}
      {hourType === 'tenHours' && <TenHoursForm fromInputRef={fromInputRef} />}
      {hourType === 'twelveHours' && <TwelveHoursForm fromInputRef={fromInputRef} />}

      {/* {hourType === 'eightHours' && (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 px-4 mt-12 mb-5 rounded-[16px]">
            <div className="relative">
              <input
                type="text"
                placeholder="Start typing city - e.g. Bangalore"
                ref={fromInputRef}
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
                style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
              />
              <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">CITY</label>
            </div>
            <div className="relative">
              <input
                type="number"
                placeholder="How many person...?"
                onChange={(e) => setPerson(e.target.value)}
                className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
                style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
              />
              <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PERSON</label>
            </div>
            <div className="relative">
              <input
                type="date"
                value={pickupDate}
                onChange={(e) => setPickupDate(e.target.value)}
                className="border-b font-['Open_Sans'] p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
                style={{ fontSize: '13px' }}
              />
              <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PICKUP DATE</label>
            </div>
            <div className="relative">
              <input
                type="time"
                value={pickupTime}
                onChange={(e) => setPickupTime(e.target.value)}
                className="border-b p-3 font-['Open_Sans'] w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
                style={{ fontSize: '12px', '--placeholder-font-size': '15px' }}
              />
              <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PICKUP AT TIME</label>
            </div>
          </div>
          <div className="flex justify-center mt-6">
            <button type="submit" className="bg-customBlue text-customfontBlack absolute py-3 px-16 text-lg rounded-md font-[700] mt-[-5px] font-nunito">
              EXPLORE CABS
            </button>
          </div>
        </form>
      )} */}

      {/* {hourType === 'tenHours' && (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 px-4 mt-12 mb-5 rounded-[16px]">
            <div className="relative">
              <input
                type="text"
                placeholder="Start typing city - e.g. Bangalore"
                ref={fromInputRef}
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
                style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
              />
              <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">CITY</label>
            </div>
            <div className="relative">
              <input
                type="number"
                placeholder="How many person...?"
                onChange={(e) => setPerson(e.target.value)}
                className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
                style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
              />
              <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PERSON</label>
            </div>
            <div className="relative">
              <input
                type="date"
                value={pickupDate}
                onChange={(e) => setPickupDate(e.target.value)}
                className="border-b font-['Open_Sans'] p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
                style={{ fontSize: '13px' }}
              />
              <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PICKUP DATE</label>
            </div>
            <div className="relative">
              <input
                type="time"
                value={pickupTime}
                onChange={(e) => setPickupTime(e.target.value)}
                className="border-b p-3 font-['Open_Sans'] w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
                style={{ fontSize: '12px', '--placeholder-font-size': '15px' }}
              />
              <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PICKUP AT TIME</label>
            </div>
          </div>
          <div className="flex justify-center mt-6">
            <button type="submit" className="bg-customBlue text-customfontBlack absolute py-3 px-16 text-lg rounded-md font-[700] mt-[-5px] font-nunito">
              EXPLORE CABS
            </button>
          </div>
        </form>
      )}

      {hourType === 'twelveHours' && (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 px-4 mt-12 mb-5 rounded-[16px]">
            <div className="relative">
              <input
                type="text"
                placeholder="Start typing city - e.g. Bangalore"
                ref={fromInputRef}
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
                style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
              />
              <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">CITY</label>
            </div>
            <div className="relative">
              <input
                type="number"
                placeholder="How many person...?"
                onChange={(e) => setPerson(e.target.value)}
                className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
                style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
              />
              <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PERSON</label>
            </div>
            <div className="relative">
              <input
                type="date"
                value={pickupDate}
                onChange={(e) => setPickupDate(e.target.value)}
                className="border-b font-['Open_Sans'] p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
                style={{ fontSize: '13px' }}
              />
              <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PICKUP DATE</label>
            </div>
            <div className="relative">
              <input
                type="time"
                value={pickupTime}
                onChange={(e) => setPickupTime(e.target.value)}
                className="border-b p-3 font-['Open_Sans'] w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
                style={{ fontSize: '12px', '--placeholder-font-size': '15px' }}
              />
              <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PICKUP AT TIME</label>
            </div>
          </div>
          <div className="flex justify-center mt-6">
            <button type="submit" className="bg-customBlue text-customfontBlack absolute py-3 px-16 text-lg rounded-md font-[700] mt-[-5px] font-nunito">
              EXPLORE CABS
            </button>
          </div>
        </form>
      )} */}
    </>
  );
}

export default LocalPage;
